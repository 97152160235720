var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.dialogVisible,
            direction: _vm.direction,
            size: "1200px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.orderDatalist
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "detailHead" }, [
                    _c("div", { staticClass: "full" }, [
                      _c("div", { staticClass: "order_icon" }, [
                        _c("span", { staticClass: "iconfont icon-dingdan" }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("orderTypeFilter")(_vm.orderDatalist.type)
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", { staticClass: "mr20" }, [
                            _vm._v(
                              "订单号：" + _vm._s(_vm.orderDatalist.orderNo)
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "list" }, [
                      _c("li", { staticClass: "item" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("订单状态"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "color-warning" }, [
                          _vm.orderDatalist.refundStatus === 3
                            ? _c("span", [_vm._v("已退款")])
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("orderStatusFilter")(
                                      _vm.orderDatalist.status
                                    )
                                  )
                                ),
                              ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "item" }, [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("下单时间"),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("filterEmpty")(_vm.orderDatalist.payTime)
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "border-card" },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "订单信息", name: "detail" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "detailSection",
                              staticStyle: { border: "none" },
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("用户信息"),
                              ]),
                              _vm._v(" "),
                              _c("ul", { staticClass: "list" }, [
                                _c("li", { staticClass: "item" }, [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("用户名称："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      _vm._s(_vm.orderDatalist.nikeName) +
                                        " | " +
                                        _vm._s(_vm.orderDatalist.siteCode)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("li", { staticClass: "item" }, [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("用户电话："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(_vm._s(_vm.orderDatalist.phone)),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.orderDatalist.shippingType < 2,
                                  expression: "orderDatalist.shippingType < 2",
                                },
                              ],
                              staticClass: "detailSection",
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("收货信息"),
                              ]),
                              _vm._v(" "),
                              _c("ul", { staticClass: "list" }, [
                                _c("li", { staticClass: "item" }, [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("收货人："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.orderDatalist.realName) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("li", { staticClass: "item" }, [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("收货电话："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.orderDatalist.userPhone) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("li", { staticClass: "item" }, [
                                  _c("div", { staticClass: "tips" }, [
                                    _vm._v("收货地址："),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "value" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.orderDatalist.userAddress) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "detailSection" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("订单信息"),
                            ]),
                            _vm._v(" "),
                            _c("ul", { staticClass: "list" }, [
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("产品总数："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.orderDatalist.totalNum)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "tips" }, [
                                  _vm._v("配送方式："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      parseFloat(
                                        _vm.orderDatalist.shippingType
                                      ) === 1
                                        ? "邮寄"
                                        : "门店自提"
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "item" }, [
                                _c("div", { staticClass: "lang" }, [
                                  _vm._v("下单时间："),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        _vm.orderDatalist.payTime
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "detailSection" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("买家留言"),
                            ]),
                            _vm._v(" "),
                            _c("ul", { staticClass: "list" }, [
                              _c("li", { staticClass: "item" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        _vm.orderDatalist.userRemark
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "detailSection" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("订单附件"),
                            ]),
                            _vm._v(" "),
                            _c("ul", { staticClass: "list" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-wrap": "wrap",
                                    width: "100%",
                                  },
                                },
                                _vm._l(
                                  _vm.orderDatalist.images,
                                  function (item) {
                                    return _c("el-image", {
                                      key: item,
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px",
                                        "margin-right": "10px",
                                        "margin-bottom": "10px",
                                      },
                                      attrs: {
                                        src: item,
                                        "preview-src-list":
                                          _vm.orderDatalist.images,
                                        fit: "contain",
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "detailSection" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("商家备注"),
                            ]),
                            _vm._v(" "),
                            _c("ul", { staticClass: "list" }, [
                              _c("li", { staticClass: "item" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        _vm.orderDatalist.merchantRemark
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        {
                          staticClass: "tabBox",
                          attrs: { label: "产品信息", name: "goods" },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              staticClass: "mt20 orderDetailList",
                              attrs: {
                                data: _vm.orderDatalist.orderDetailList,
                                size: "small",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品信息",
                                  "min-width": "400",
                                  "show-overflow-tooltip": true,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "acea-row row-middle",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "demo-image__preview mr15",
                                                },
                                                [
                                                  _c("el-image", {
                                                    attrs: {
                                                      src: scope.row.image,
                                                      "preview-src-list": [
                                                        scope.row.image,
                                                      ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "408px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "line1 mb10",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.productName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "line1 color-909399 line-heightOne",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "规格：" +
                                                          _vm._s(scope.row.sku)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1304476684
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "产品售价", "min-width": "90" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "acea-row row-middle",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "line1" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(scope.row.price) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2818217796
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "购买数量", "min-width": "90" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "acea-row row-middle",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "line1" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(scope.row.payNum) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  458142903
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "发货数量", "min-width": "90" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "acea-row row-middle",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "line1" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        scope.row.deliveryNum
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2284783555
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "售后数量", "min-width": "90" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "acea-row row-middle",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "line1 mb10 line-heightOne",
                                                },
                                                [
                                                  _vm._v(
                                                    "退款中：" +
                                                      _vm._s(
                                                        scope.row.applyRefundNum
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "line1 line-heightOne",
                                                },
                                                [
                                                  _vm._v(
                                                    "退款成功：" +
                                                      _vm._s(
                                                        scope.row.refundNum
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  713679369
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.orderDatalist.status > 1 &&
                      _vm.orderDatalist.status < 9 &&
                      _vm.orderDatalist.type < 5
                        ? _c(
                            "el-tab-pane",
                            {
                              staticClass: "tabBox",
                              attrs: { label: "发货记录", name: "delivery" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "10px",
                                  },
                                  attrs: {
                                    size: "mini",
                                    data: _vm.logisticsList,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packageNo",
                                      "show-overflow-tooltip": "",
                                      label: "箱号码",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "startNo",
                                      "show-overflow-tooltip": "",
                                      label: "产品起号",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "endNo",
                                      "show-overflow-tooltip": "",
                                      label: "产品止号",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              parseFloat(_vm.orderDatalist.shippingType) ===
                                1 && _vm.InvoiceList.length
                                ? _c(
                                    "div",
                                    [
                                      _vm._l(_vm.InvoiceList, function (item) {
                                        return [
                                          _c(
                                            "el-table",
                                            {
                                              key: item.id,
                                              staticClass: "mt20",
                                              attrs: {
                                                data: item.detailList,
                                                size: "small",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: { "min-width": "400" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "header",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.expressName +
                                                                  ":" +
                                                                  item.trackingNumber
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml30",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.createTime
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "acea-row row-middle",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "demo-image__preview mr15",
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-image",
                                                                    {
                                                                      attrs: {
                                                                        src: scope
                                                                          .row
                                                                          .image,
                                                                        "preview-src-list":
                                                                          [
                                                                            scope
                                                                              .row
                                                                              .image,
                                                                          ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "line1 mb10 line-heightOne",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .productName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "line1 color-909399 line-heightOne",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "规格：" +
                                                                        _vm._s(
                                                                          scope
                                                                            .row
                                                                            .sku
                                                                        )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _c("el-table-column", {
                                                attrs: { "min-width": "90" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "header",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "hasPermi",
                                                                  rawName:
                                                                    "v-hasPermi",
                                                                  value: [
                                                                    "platform:order:logistics:info",
                                                                  ],
                                                                  expression:
                                                                    "['platform:order:logistics:info']",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "5px",
                                                                "line-height":
                                                                  "1",
                                                                height: "auto",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openLogistics(
                                                                      item.id
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "物流查询\n                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "acea-row row-middle",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "line1 font12 color-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "X " +
                                                                      _vm._s(
                                                                        scope
                                                                          .row
                                                                          .num
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      }),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              parseFloat(_vm.orderDatalist.shippingType) === 2
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "detailSection" },
                                      [
                                        _c("ul", { staticClass: "list" }, [
                                          _c("li", { staticClass: "item" }, [
                                            _c("div", [_vm._v("核销员名称：")]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "value" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.orderDatalist.clerkName
                                                  ) +
                                                    " | " +
                                                    _vm._s(
                                                      _vm.orderDatalist.clerkId
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.orderDatalist
        ? _c(
            "el-dialog",
            {
              attrs: { title: "提示", visible: _vm.modal2, width: "600px" },
              on: {
                "update:visible": function ($event) {
                  _vm.modal2 = $event
                },
              },
            },
            [
              _c("div", { staticClass: "logistics acea-row row-top" }, [
                _c("div", { staticClass: "logistics_img" }, [
                  _c("img", {
                    attrs: { src: require("@/assets/imgs/expressi.jpg") },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logistics_cent" }, [
                  _c("span", { staticClass: "mb10" }, [
                    _vm._v("物流公司：" + _vm._s(_vm.resultInfo.expName)),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("物流单号：" + _vm._s(_vm.resultInfo.number)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resultInfo.courierPhone,
                          expression: "resultInfo.courierPhone",
                        },
                      ],
                    },
                    [_vm._v("快递站：" + _vm._s(_vm.resultInfo.courierPhone))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resultInfo.courierPhone,
                          expression: "resultInfo.courierPhone",
                        },
                      ],
                    },
                    [
                      _vm._v(
                        "快递员电话：" + _vm._s(_vm.resultInfo.courierPhone)
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "acea-row row-column-around trees-coadd" },
                [
                  _c(
                    "div",
                    { staticClass: "scollhide" },
                    [
                      _c(
                        "el-timeline",
                        { attrs: { reverse: _vm.reverse } },
                        _vm._l(_vm.result, function (item, i) {
                          return _c("el-timeline-item", { key: i }, [
                            _c("p", {
                              staticClass: "time",
                              domProps: { textContent: _vm._s(item.time) },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "content",
                              domProps: { textContent: _vm._s(item.status) },
                            }),
                          ])
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.modal2 = false
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }