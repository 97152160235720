var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hot_imgs borderPadding" },
    [
      _vm.configData.tips
        ? _c("div", { staticClass: "from-tips" }, [
            _vm._v("\n    " + _vm._s(_vm.configData.tips) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list-box mt20" },
        [
          _c(
            "draggable",
            {
              staticClass: "dragArea list-group",
              attrs: {
                list: _vm.configData.list,
                group: "peoples",
                handle: ".move-icon",
              },
            },
            _vm._l(_vm.configData.list, function (item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("div", { staticClass: "move-icon" }, [
                  _c("span", { staticClass: "iconfont icon-tuozhuaidian" }),
                ]),
                _vm._v(" "),
                _vm.configData.isShowImageUrl
                  ? _c(
                      "div",
                      {
                        staticClass: "img-box",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap(item, index)
                          },
                        },
                      },
                      [
                        item.imageUrl
                          ? _c("img", {
                              attrs: { src: item.imageUrl, alt: "" },
                            })
                          : _c("div", { staticClass: "upload-box" }, [
                              _c("i", {
                                staticClass: "el-icon-camera-solid",
                                staticStyle: { "font-size": "30px" },
                              }),
                            ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "info" }, [
                  _vm.configData.isShowName
                    ? _c("div", { staticClass: "info-item" }, [
                        _c("span", [_vm._v(_vm._s(_vm.configData.title))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-box" },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "请输入标题",
                                maxlength: _vm.configData.max,
                              },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.configData.isShowLinkUrl
                    ? _c("div", { staticClass: "info-item" }, [
                        _c("span", [_vm._v("链接")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-box" },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "请输入链接",
                              },
                              model: {
                                value: item.linkUrl,
                                callback: function ($$v) {
                                  _vm.$set(item, "linkUrl", $$v)
                                },
                                expression: "item.linkUrl",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.configData.isShowStatus
                    ? _c("div", { staticClass: "info-item" }, [
                        _c("span", [_vm._v("状态")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-box" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                                "active-text": "显示",
                                "inactive-text": "隐藏",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onchangeIsShow(item.status, index)
                                },
                              },
                              model: {
                                value: item.status,
                                callback: function ($$v) {
                                  _vm.$set(item, "status", $$v)
                                },
                                expression: "item.status",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.configData.isShowEdit
                    ? _c(
                        "div",
                        {
                          staticClass: "delect-btn",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.bindDelete(item, index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-error",
                            staticStyle: { "font-size": "26px" },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.configData.isShowAddBtn
        ? [
            _vm.configData.list.length < _vm.configData.maxList ||
            !_vm.configData.maxList
              ? _c(
                  "div",
                  { staticClass: "add-btn mt20" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button",
                        attrs: { icon: "el-icon-plus", plain: "" },
                        on: { click: _vm.addBox },
                      },
                      [_vm._v("添加模块")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }