"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _auth = require("@/utils/auth");
var _productCdkey = require("@/api/productCdkey");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'cdkeyLibrary',
  props: {
    cdkeyLibraryInfo: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  data: function data() {
    return {
      cdkeyShow: false,
      header: {},
      //请求头部信息
      carMyValidateForm: {
        cdkeyInfo: null
      },
      //卡密对象
      btnloading: false,
      cdkeyLibraryList: []
    };
  },
  watch: {
    cdkeyLibraryInfo: {
      handler: function handler(nVal, oVal) {
        this.carMyValidateForm.cdkeyInfo = nVal;
        this.getCdkeyLibraryList();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.carMyValidateForm.cdkeyInfo = this.cdkeyLibraryInfo;
  },
  methods: {
    //卡密列表
    getCdkeyLibraryList: function getCdkeyLibraryList() {
      var _this = this;
      (0, _productCdkey.productUnrelatedListApi)().then(function (res) {
        if (_this.carMyValidateForm.cdkeyInfo.id) {
          // 使用 Array.some() 方法检查数组中是否包含该对象
          var isObjectFound = res.some(function (item) {
            return JSON.stringify(item) === JSON.stringify(_this.carMyValidateForm.cdkeyInfo);
          });
          if (!isObjectFound) {
            // 如果数组中不包含该对象，则将其添加到数组中
            res.push(_this.carMyValidateForm.cdkeyInfo);
          }
        }
        _this.cdkeyLibraryList = res;
      });
    },
    handleChange: function handleChange(event) {
      this.carMyValidateForm.cdkeyInfo = event;
    },
    //保存
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.cdkeyShow = false;
          _this2.$emit('handlerSubSuccess', _this2.carMyValidateForm.cdkeyInfo);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};