var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _vm.checkPermi(["merchant:product:page:list"])
        ? _c(
            "el-card",
            {
              staticClass: "ivu-mt",
              attrs: {
                bordered: false,
                shadow: "never",
                "body-style": { padding: 0 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "padding-add" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        inline: "",
                        size: "small",
                        "label-position": "right",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台分类：" } },
                        [
                          _c("el-cascader", {
                            staticClass: "form_content_width",
                            attrs: {
                              options: _vm.adminProductClassify,
                              props: _vm.propsPlant,
                              clearable: "",
                              size: "small",
                              placeholder: "请输入平台产品分类",
                            },
                            on: { change: _vm.handleSeachList },
                            model: {
                              value: _vm.tableFrom.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "categoryId", $$v)
                              },
                              expression: "tableFrom.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商户分类：" } },
                        [
                          _c("el-cascader", {
                            staticClass: "form_content_width mr20",
                            attrs: {
                              options: _vm.merProductClassify,
                              props: _vm.propsMer,
                              clearable: "",
                              size: "small",
                              placeholder: "请输入商户产品分类",
                            },
                            on: { change: _vm.handleSeachList },
                            model: {
                              value: _vm.tableFrom.cateId,
                              callback: function ($$v) {
                                _vm.$set(_vm.tableFrom, "cateId", $$v)
                              },
                              expression: "tableFrom.cateId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品搜索：" } },
                        [
                          _c("el-input", {
                            staticClass: "form_content_width",
                            attrs: {
                              placeholder: "请输入产品名称关键字",
                              size: "small",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSeachList($event)
                              },
                            },
                            model: {
                              value: _vm.keywords,
                              callback: function ($$v) {
                                _vm.keywords =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "keywords",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleSeachList },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _vm.checkPermi(["merchant:product:page:list"])
            ? _c(
                "div",
                { ref: "headerBox", staticClass: "clearfix" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticClass: "list-tabs mb5",
                      on: { "tab-click": _vm.handleSeachList },
                      model: {
                        value: _vm.tableFrom.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "type", $$v)
                        },
                        expression: "tableFrom.type",
                      },
                    },
                    _vm._l(_vm.headeNum, function (item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: {
                          label: item.name + "(" + item.count + ")",
                          name: item.type.toString(),
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:product:save"],
                  expression: "['merchant:product:save']",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd("isAdd")
                },
              },
            },
            [_vm._v("添加产品")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:product:import:product"],
                  expression: "['merchant:product:import:product']",
                },
              ],
              staticClass: "mr14",
              attrs: { size: "small", type: "success" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd("isCopy")
                },
              },
            },
            [_vm._v("产品采集")]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { attrs: { size: "small" } },
            [
              _c(
                "el-button",
                {
                  class: _vm.checkedIds.length > 0 ? "" : "active",
                  attrs: { disabled: _vm.checkedIds.length > 0 ? false : true },
                },
                [
                  _vm._v("\n        批量设置"),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  (_vm.checkedIds.length > 0 ? true : false)
                    ? [
                        _vm.tableFrom.type ==
                          _vm.ProductTypeEnum.InTheWarehouse &&
                        _vm.checkPermi([
                          "merchant:product:batch:set:freight:template",
                        ])
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleSetFreight()
                                  },
                                },
                              },
                              [_vm._v("设置运费")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type ==
                          _vm.ProductTypeEnum.InTheWarehouse &&
                        _vm.checkPermi(["merchant:product:batch:set:brokerage"])
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleSetCommission()
                                  },
                                },
                              },
                              [_vm._v("设置佣金")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:product:batch:down"]) &&
                        (_vm.tableFrom.type == _vm.ProductTypeEnum.OnSale ||
                          _vm.SoldOutAndAlertInventory)
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.batchDelisting("down")
                                  },
                                },
                              },
                              [_vm._v("批量下架")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:product:batch:up"]) &&
                        (_vm.tableFrom.type ==
                          _vm.ProductTypeEnum.InTheWarehouse ||
                          _vm.SoldOutAndAlertInventory)
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.batchDelisting("up")
                                  },
                                },
                              },
                              [_vm._v("批量上架")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:product:batch:restore"]) &&
                        _vm.tableFrom.type == _vm.ProductTypeEnum.RecycleBin
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleRestore()
                                  },
                                },
                              },
                              [_vm._v("批量恢复")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:product:batch:delete"]) &&
                        _vm.tableFrom.type == _vm.ProductTypeEnum.RecycleBin
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleRecycleBin(
                                      _vm.tableFrom.type
                                    )
                                  },
                                },
                              },
                              [_vm._v("批量删除")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type ===
                          _vm.ProductTypeEnum.PendingReview &&
                        _vm.checkPermi(["merchant:product:batch:submit:audit"])
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handlePendingReview()
                                  },
                                },
                              },
                              [_vm._v("提交审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type ==
                          _vm.ProductTypeEnum.InTheWarehouse &&
                        _vm.checkPermi([
                          "merchant:product:batch:add:feedback:coupons",
                        ])
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleAddCoupon()
                                  },
                                },
                              },
                              [_vm._v("添加回馈券")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:product:batch:recycle"]) &&
                        _vm.RecycleBin
                          ? _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleRecycleBin(
                                      _vm.tableFrom.type
                                    )
                                  },
                                },
                              },
                              [_vm._v("加入回收站")]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": true,
                "highlight-current-row": "",
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "select-all": _vm.selectAll,
                select: _vm.selectOne,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c("el-form-item", { attrs: { label: "收藏：" } }, [
                              _c("span", [
                                _vm._v(_vm._s(props.row.collectCount)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "其他销量：" } },
                              [_c("span", [_vm._v(_vm._s(props.row.ficti))])]
                            ),
                            _vm._v(" "),
                            _vm.tableFrom.type == 7
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "拒绝原因：" } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(props.row.reason)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _vm.checkedCities.includes("ID")
                ? _c("el-table-column", {
                    attrs: { prop: "id", label: "ID", "min-width": "50" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("产品图")
                ? _c("el-table-column", {
                    attrs: { label: "产品图", "min-width": "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "demo-image__preview line-heightOne",
                                },
                                [
                                  _c("el-image", {
                                    attrs: {
                                      src: scope.row.image,
                                      "preview-src-list": [scope.row.image],
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1508102206
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("产品名称")
                ? _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      prop: "name",
                      "min-width": "200",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("产品售价")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "price",
                      label: "产品售价",
                      "min-width": "90",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("销量")
                ? _c("el-table-column", {
                    attrs: { prop: "sales", label: "销量", "min-width": "90" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("库存")
                ? _c("el-table-column", {
                    attrs: { prop: "stock", label: "库存", "min-width": "90" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.tableFrom.type === "7"
                ? _c("el-table-column", {
                    attrs: {
                      label: "失败原因",
                      "min-width": "150",
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", { staticClass: "textE93323" }, [
                                _vm._v(_vm._s(scope.row.reason)),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3797934948
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("状态")
                ? _c("el-table-column", {
                    attrs: { label: "状态", "min-width": "80", fixed: "right" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.checkPermi([
                                "merchant:product:up",
                                "merchant:product:down",
                              ])
                                ? _c("el-switch", {
                                    attrs: {
                                      disabled: _vm.IsShow,
                                      "active-value": true,
                                      "inactive-value": false,
                                      "active-text": "上架",
                                      "inactive-text": "下架",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onchangeIsShow(scope.row)
                                      },
                                    },
                                    model: {
                                      value: scope.row.isShow,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "isShow", $$v)
                                      },
                                      expression: "scope.row.isShow",
                                    },
                                  })
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(scope.row.isShow ? "上架" : "下架")
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1954848495
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "190",
                  fixed: "right",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.type !==
                          _vm.ProductTypeEnum.InTheWarehouse &&
                        _vm.tableFrom.type !==
                          _vm.ProductTypeEnum.AlertInventory &&
                        _vm.tableFrom.type !==
                          _vm.ProductTypeEnum.PendingReview &&
                        _vm.checkPermi(["merchant:product:info"])
                          ? [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      path:
                                        "/product/list/creatProduct/" +
                                        scope.row.id +
                                        "/1/2/" +
                                        scope.row.type,
                                    },
                                  },
                                },
                                [_vm._v("\n              详情\n            ")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:product:submit:audit"]) &&
                        _vm.tableFrom.type === _vm.ProductTypeEnum.PendingReview
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePendingReview(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("提交审核")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type !== "5" &&
                        _vm.tableFrom.type !== "6" &&
                        _vm.checkPermi(["merchant:product:update"])
                          ? [
                              _vm.tableFrom.type !== "2" &&
                              _vm.tableFrom.type !== "4"
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type === "2" &&
                        scope.row.auditStatus === 1 &&
                        _vm.checkPermi(["merchant:product:submit:audit"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAudit(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("提审")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type === "5" &&
                        _vm.checkPermi(["merchant:product:restor"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRestore(
                                        scope.row,
                                        scope.$index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("恢复产品")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.tableFrom.type === _vm.ProductTypeEnum.OnSale ||
                          (_vm.tableFrom.type ===
                            _vm.ProductTypeEnum.AlertInventory &&
                            scope.row.isShow) ||
                          (_vm.tableFrom.type === _vm.ProductTypeEnum.SoldOut &&
                            scope.row.isShow)) &&
                        _vm.checkPermi(["merchant:product:quick:stock:add"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row, true)
                                    },
                                  },
                                },
                                [_vm._v("编辑库存")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.tableFrom.type ===
                          _vm.ProductTypeEnum.InTheWarehouse ||
                          (_vm.tableFrom.type === _vm.ProductTypeEnum.SoldOut &&
                            !scope.row.isShow) ||
                          (_vm.tableFrom.type ===
                            _vm.ProductTypeEnum.AlertInventory &&
                            !scope.row.isShow)) &&
                        _vm.checkPermi(["merchant:product:review:free:edit"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row, false)
                                    },
                                  },
                                },
                                [_vm._v("免审编辑")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.tableFrom.type === _vm.ProductTypeEnum.Audit ||
                          _vm.tableFrom.type ===
                            _vm.ProductTypeEnum.ReviewFailed ||
                          _vm.tableFrom.type ===
                            _vm.ProductTypeEnum.RecycleBin) &&
                        _vm.checkPermi(["merchant:product:delete"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(
                                        scope.row.id,
                                        _vm.tableFrom.type
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tableFrom.type === "5"
                                        ? "删除"
                                        : "加入回收站"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type === _vm.ProductTypeEnum.PendingReview
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { attrs: { size: "small", trigger: "click" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(" 更多"),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      _vm.checkPermi(["merchant:product:info"])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              staticClass: "infoItem",
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleInfo(
                                                    scope.row.id,
                                                    scope.row.type
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("详情\n                ")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "merchant:product:delete",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.row.id,
                                                    _vm.tableFrom.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tableFrom.type === "5"
                                                    ? "删除"
                                                    : "加入回收站"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.type ===
                          _vm.ProductTypeEnum.InTheWarehouse ||
                        _vm.tableFrom.type ===
                          _vm.ProductTypeEnum.AlertInventory ||
                        (_vm.operation && !scope.row.isShow)
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-dropdown",
                                { attrs: { size: "small", trigger: "click" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "el-dropdown-link" },
                                    [
                                      _vm._v(" 更多"),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    [
                                      (_vm.tableFrom.type === "2" ||
                                        _vm.tableFrom.type === "4") &&
                                      _vm.checkPermi(["merchant:product:info"])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              staticClass: "infoItem",
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleInfo(
                                                    scope.row.id,
                                                    scope.row.type
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("详情\n                ")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "merchant:product:set:freight:template",
                                      ]) &&
                                      scope.row.type !== 5 &&
                                      scope.row.type !== 6
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleSetFreight(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("设置运费")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "merchant:product:set:brokerage",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleSetCommission(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("设置佣金")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "merchant:product:add:feedback:coupons",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleAddCoupon(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("添加回馈券")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.checkPermi([
                                        "merchant:product:delete",
                                      ])
                                        ? _c(
                                            "el-dropdown-item",
                                            {
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.row.id,
                                                    _vm.tableFrom.type
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tableFrom.type === "5"
                                                    ? "删除"
                                                    : "加入回收站"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.card_select_show,
                  expression: "card_select_show",
                },
              ],
              staticClass: "card_abs",
            },
            [
              [
                _c(
                  "div",
                  { staticClass: "cell_ht" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { indeterminate: _vm.isIndeterminate },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function ($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll",
                        },
                      },
                      [_vm._v("全选")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.checkSave()
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-checkbox-group",
                  {
                    on: { change: _vm.handleCheckedCitiesChange },
                    model: {
                      value: _vm.checkedCities,
                      callback: function ($$v) {
                        _vm.checkedCities = $$v
                      },
                      expression: "checkedCities",
                    },
                  },
                  _vm._l(_vm.columnData, function (item) {
                    return _c(
                      "el-checkbox",
                      {
                        key: item,
                        staticClass: "check_cell",
                        attrs: { label: item },
                      },
                      [_vm._v(_vm._s(item))]
                    )
                  }),
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          staticClass: "showHeader",
          attrs: {
            title: !_vm.stockEdit ? "免审编辑" : "编辑库存",
            visible: _vm.drawer,
            direction: _vm.direction,
            size: 1280,
            "before-close": _vm.handleCloseEdit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _vm.drawer
            ? _c("store-edit", {
                attrs: {
                  productId: _vm.productId,
                  productType: _vm.productType,
                  stockEdit: _vm.stockEdit,
                  isSub: _vm.isSub,
                },
                on: { subSuccess: _vm.subSuccess },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-top",
          attrs: {
            visible: _vm.dialogVisible,
            title: "设置运费",
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "75px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "运费模板：", prop: "tempId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mr20",
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formValidate.tempId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "tempId", $$v)
                        },
                        expression: "formValidate.tempId",
                      },
                    },
                    _vm._l(_vm.shippingTemplates, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "dialog-footer-inner" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btns",
                        attrs: { size: "small" },
                        on: { click: _vm.handleClose },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _vm.checkPermi(["merchant:product:update"])
                      ? _c(
                          "el-button",
                          {
                            staticClass: "submission",
                            attrs: { type: "primary", loading: _vm.loadingBtn },
                            on: {
                              click: function ($event) {
                                return _vm.handleSubmit("formValidate")
                              },
                            },
                          },
                          [_vm._v("确定")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogCommision
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "设置佣金",
                visible: _vm.dialogCommision,
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogCommision = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "commisionForm",
                  attrs: { model: _vm.commisionForm, rules: _vm.commisionRule },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "一级佣金比例：", prop: "extension_one" },
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "priceBox",
                        attrs: {
                          step: 1,
                          "step-strictly": "",
                          min: 0,
                          max: 100,
                          "controls-position": "right",
                        },
                        model: {
                          value: _vm.commisionForm.brokerage,
                          callback: function ($$v) {
                            _vm.$set(_vm.commisionForm, "brokerage", $$v)
                          },
                          expression: "commisionForm.brokerage",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "二级佣金比例：", prop: "extension_two" },
                    },
                    [
                      _c("el-input-number", {
                        staticClass: "priceBox",
                        attrs: {
                          step: 1,
                          min: 0,
                          "step-strictly": "",
                          max: 100,
                          "controls-position": "right",
                        },
                        model: {
                          value: _vm.commisionForm.brokerageTwo,
                          callback: function ($$v) {
                            _vm.$set(_vm.commisionForm, "brokerageTwo", $$v)
                          },
                          expression: "commisionForm.brokerageTwo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", [
                    _c("span", [
                      _vm._v(
                        "备注：订单交易成功后给推广员返佣的比例，例:一级佣金比例设置5，则返订单金额的5%"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogCommision = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCommisionForm("commisionForm")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("product-tpye", {
        ref: "productTpye",
        attrs: { addType: _vm.addType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }