var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.proTypedialogVisible,
            title: "选择产品类型",
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.proTypedialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: { model: _vm.formValidate, "label-width": "120px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品类型：", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "acea-row" },
                    _vm._l(_vm.productType, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "virtual",
                          class:
                            _vm.formValidate.type == item.id
                              ? "virtual_boder"
                              : "virtual_boder2",
                          on: {
                            click: function ($event) {
                              return _vm.handleChangeProductType(item.id, 2)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "virtual_top" }, [
                            _vm._v(_vm._s(item.tit)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "virtual_bottom" }, [
                            _vm._v("(" + _vm._s(item.tit2) + ")"),
                          ]),
                          _vm._v(" "),
                          _vm.formValidate.type == item.id
                            ? _c("div", { staticClass: "virtual_san" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.formValidate.type == item.id
                            ? _c("div", { staticClass: "virtual_dui" }, [
                                _vm._v("✓"),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "font12 add-product-title" }, [
                    _vm._v("云盘产品、卡密产品不支持用户申请售后。"),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.proTypedialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSureType },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }