var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editDataLocal",
          staticClass: "demo-dynamic",
          attrs: { model: _vm.editDataLocal, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "配置名称：",
                prop: "printName",
                rules: {
                  required: true,
                  message: "打印机配置名称不能为空",
                  trigger: "blur",
                },
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "打印机配置名称" },
                model: {
                  value: _vm.editDataLocal.printName,
                  callback: function ($$v) {
                    _vm.$set(_vm.editDataLocal, "printName", $$v)
                  },
                  expression: "editDataLocal.printName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "打印机类型：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.editDataLocal.printType,
                    callback: function ($$v) {
                      _vm.$set(_vm.editDataLocal, "printType", $$v)
                    },
                    expression: "editDataLocal.printType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("易联云")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("飞鹅云")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.editDataLocal.printType === 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "AppId：",
                        prop: "printYlyAppid",
                        rules: {
                          required: true,
                          message: "AppId不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "AppId" },
                        model: {
                          value: _vm.editDataLocal.printYlyAppid,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDataLocal, "printYlyAppid", $$v)
                          },
                          expression: "editDataLocal.printYlyAppid",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Userid：",
                        prop: "printYlyUserid",
                        rules: {
                          required: true,
                          message: "Userid不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Userid" },
                        model: {
                          value: _vm.editDataLocal.printYlyUserid,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDataLocal, "printYlyUserid", $$v)
                          },
                          expression: "editDataLocal.printYlyUserid",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Sec：",
                        prop: "printYlySec",
                        rules: {
                          required: true,
                          message: "Sec不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Sec" },
                        model: {
                          value: _vm.editDataLocal.printYlySec,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDataLocal, "printYlySec", $$v)
                          },
                          expression: "editDataLocal.printYlySec",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "打印机编码：",
                        prop: "printYlyMerchineNo",
                        rules: {
                          required: true,
                          message: "打印机编码不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "打印机编码" },
                        model: {
                          value: _vm.editDataLocal.printYlyMerchineNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.editDataLocal,
                              "printYlyMerchineNo",
                              $$v
                            )
                          },
                          expression: "editDataLocal.printYlyMerchineNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editDataLocal.printType === 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Name：",
                        prop: "printFeName",
                        rules: {
                          required: true,
                          message: "Name不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Name" },
                        model: {
                          value: _vm.editDataLocal.printFeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDataLocal, "printFeName", $$v)
                          },
                          expression: "editDataLocal.printFeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "User：",
                        prop: "printFeUser",
                        rules: {
                          required: true,
                          message: "User不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "User" },
                        model: {
                          value: _vm.editDataLocal.printFeUser,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDataLocal, "printFeUser", $$v)
                          },
                          expression: "editDataLocal.printFeUser",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ukey：",
                        prop: "printFeUkey",
                        rules: {
                          required: true,
                          message: "Ukey不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Ukey" },
                        model: {
                          value: _vm.editDataLocal.printFeUkey,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDataLocal, "printFeUkey", $$v)
                          },
                          expression: "editDataLocal.printFeUkey",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "打印机编码：",
                        prop: "printFeSn",
                        rules: {
                          required: true,
                          message: "打印机编码不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "打印机编码" },
                        model: {
                          value: _vm.editDataLocal.printFeSn,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDataLocal, "printFeSn", $$v)
                          },
                          expression: "editDataLocal.printFeSn",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "active-text": "启用",
                  "inactive-value": 0,
                  "inactive-text": "停用",
                },
                model: {
                  value: _vm.editDataLocal.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.editDataLocal, "status", $$v)
                  },
                  expression: "editDataLocal.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSubmitClickUp("editDataLocal")
                },
              },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }