var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("div", { staticClass: "header clearfix" }, [
        _c("div", { staticClass: "filter-container" }, [
          _c(
            "div",
            { staticClass: "demo-input-suffix acea-row" },
            [
              _c("span", { staticClass: "seachTiele" }, [_vm._v("产品搜索：")]),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "selWidth",
                  attrs: {
                    placeholder: "请输入产品名称，关键字，产品编号",
                    size: "small",
                  },
                  model: {
                    value: _vm.keywords,
                    callback: function ($$v) {
                      _vm.keywords = $$v
                    },
                    expression: "keywords",
                  },
                },
                [
                  _c("el-button", {
                    staticClass: "el-button-solt",
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.getList()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "table",
          staticClass: "tableSelection",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData.data,
            size: "small",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", "min-width": "40" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "产品ID", "min-width": "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("filterEmpty")(scope.row.productId))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "产品名称",
              "min-width": "130",
              "show-overflow-tooltip": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.name + "/" + scope.row.productId)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "产品图", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "demo-image__preview line-heightOne" },
                      [
                        _c("el-image", {
                          attrs: {
                            src: scope.row.coverImgUrlLocal,
                            "preview-src-list": [scope.row.coverImgUrlLocal],
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "价格类型", "min-width": "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("priceTypeFilter")(scope.row.priceType))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "价格", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.priceType === 1
                      ? _c("span", [_vm._v(_vm._s(scope.row.price))])
                      : _c("span", [
                          _vm._v(
                            _vm._s(scope.row.price + "~" + scope.row.price2)
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-sizes": _vm.$constants.page.limit,
              "page-size": _vm.tableForm.limit,
              "current-page": _vm.tableForm.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tableData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }