var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "hasPermi",
              rawName: "v-hasPermi",
              value: ["merchant:user:page:list"],
              expression: "['merchant:user:page:list']",
            },
          ],
          staticClass: "ivu-mt",
          attrs: {
            bordered: false,
            shadow: "never",
            "body-style": { padding: 0 },
          },
        },
        [
          _c(
            "div",
            { ref: "tableheader", staticClass: "padding-add" },
            [
              _c(
                "el-form",
                {
                  ref: "userFrom",
                  attrs: {
                    inline: "",
                    size: "small",
                    model: _vm.userFrom,
                    "label-width": "66px",
                    "label-position": _vm.labelPosition,
                  },
                },
                [
                  !_vm.collapse
                    ? _c(
                        "div",
                        { staticClass: "acea-row search-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "search-form-box" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户昵称：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "form_content_width",
                                    attrs: {
                                      placeholder: "请输入用户昵称",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.nikename,
                                      callback: function ($$v) {
                                        _vm.nikename =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "nikename",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "手机号码：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "form_content_width",
                                    attrs: {
                                      placeholder:
                                        "请输入手机号，不支持模糊搜索",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.userFrom.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userFrom,
                                          "phone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "userFrom.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { staticClass: "search-form-sub" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.handleSearchList },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ResetSearch",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleReset("userFrom")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "ivu-ml-8 font12 ml10",
                                  on: {
                                    click: function ($event) {
                                      _vm.collapse = !_vm.collapse
                                    },
                                  },
                                },
                                [
                                  !_vm.collapse
                                    ? [
                                        _vm._v(" 展开 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ]
                                    : [
                                        _vm._v(" 收起 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-up",
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.collapse
                    ? _c(
                        "div",
                        { staticClass: "acea-row search-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "search-form-box" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户昵称：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "form_content_width",
                                    attrs: {
                                      placeholder: "请输入用户昵称",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.nikename,
                                      callback: function ($$v) {
                                        _vm.nikename =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "nikename",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "手机号：" } },
                                [
                                  _c("el-input", {
                                    staticClass: "form_content_width",
                                    attrs: {
                                      placeholder:
                                        "请输入手机号，不支持模糊搜索",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.userFrom.phone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.userFrom,
                                          "phone",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "userFrom.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "访问时间：" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "form_content_width",
                                    attrs: {
                                      align: "right",
                                      "unlink-panels": "",
                                      "value-format": "yyyy-MM-dd",
                                      format: "yyyy-MM-dd",
                                      size: "small",
                                      type: "daterange",
                                      placement: "bottom-end",
                                      "range-separator": "-",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: { change: _vm.onchangeTime },
                                    model: {
                                      value: _vm.timeVal,
                                      callback: function ($$v) {
                                        _vm.timeVal = $$v
                                      },
                                      expression: "timeVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "性别：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: "请选择",
                                        size: "small",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.userFrom.sex,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userFrom, "sex", $$v)
                                        },
                                        expression: "userFrom.sex",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { value: "", label: "全部" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "0", label: "未知" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "1", label: "男" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "2", label: "女" },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: { value: "3", label: "保密" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "注册类型：" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "form_content_width",
                                      attrs: {
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getList(1)
                                        },
                                      },
                                      model: {
                                        value: _vm.userFrom.registerType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.userFrom,
                                            "registerType",
                                            $$v
                                          )
                                        },
                                        expression: "userFrom.registerType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.registerTypeList,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { staticClass: "search-form-sub" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "small" },
                                  on: { click: _vm.handleSearchList },
                                },
                                [_vm._v("搜索")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ResetSearch",
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleReset("userFrom")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "ivu-ml-8 font12 ml10",
                                  on: {
                                    click: function ($event) {
                                      _vm.collapse = !_vm.collapse
                                    },
                                  },
                                },
                                [
                                  !_vm.collapse
                                    ? [
                                        _vm._v(" 展开 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-down",
                                        }),
                                      ]
                                    : [
                                        _vm._v(" 收起 "),
                                        _c("i", {
                                          staticClass: "el-icon-arrow-up",
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                height: _vm.tableHeight,
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand", width: "40" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-table-expand",
                            attrs: { "label-position": "left", inline: "" },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "真实姓名：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(props.row.realName)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "性别：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("sexFilter")(props.row.sex))
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "首次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.createTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "近次访问：" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(
                                        props.row.lastLoginTime
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-form-item", { attrs: { label: "备注：" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm._f("filterEmpty")(props.row.mark))
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "头像", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview line-heightOne" },
                          [
                            _c("el-image", {
                              attrs: {
                                src: scope.row.avatar,
                                "preview-src-list": [scope.row.avatar],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.checkedCities.includes("昵称")
                ? _c("el-table-column", {
                    attrs: { label: "昵称", "min-width": "130" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  class:
                                    scope.row.isLogoff == true ? "red" : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("filterEmpty")(scope.row.nickname)
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              scope.row.isLogoff == true
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        scope.row.isLogoff == true ? "red" : "",
                                    },
                                    [_vm._v("|")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.isLogoff == true
                                ? _c("span", { staticClass: "red" }, [
                                    _vm._v("(已注销)"),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3133054448
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "手机号", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("filterEmpty")(scope.row.phone))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "registerType",
                  label: "注册类型",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              color: _vm.filterRegisterType(
                                scope.row.registerType
                              ),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("registerTypeFilter")(
                                  scope.row.registerType
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.checkPermi(["merchant:user:detail"])
                ? _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "70",
                      fixed: "right",
                      "render-header": _vm.renderHeader,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDetails(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("用户详情")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1844531217
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.userFrom.limit,
                  "current-page": _vm.userFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
          style: { top: _vm.collapse ? 570 + "px" : 270 + "px" },
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("detail-user", { ref: "userDetailFrom" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }