var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout-footer mt15" }, [
    _c("div", { staticClass: "layout-footer-warp" }, [_c("iCopyright")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }