"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vertical = _interopRequireDefault(require("@/layout/navMenu/vertical.vue"));
var _index = _interopRequireDefault(require("@/layout/logo/index.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'layoutAside',
  components: {
    Vertical: _vertical.default,
    Logo: _index.default
  },
  data: function data() {
    return {
      // menuList: [],
      clientWidth: '',
      catName: ''
    };
  },
  computed: {
    adminTitle: function adminTitle() {
      return this.$store.state.app.adminTitle || '';
    },
    // 设置左侧菜单的具体宽度
    menuList: function menuList() {
      this.$store.state.user.childMenuList.length > 0 ? this.$store.state.themeConfig.themeConfig.isCollapse = false : this.$store.state.themeConfig.themeConfig.isCollapse = true;
      return this.$store.state.user.childMenuList;
    },
    setCollapseWidth: function setCollapseWidth() {
      var _this$$store$state$th = this.$store.state.themeConfig.themeConfig,
        layout = _this$$store$state$th.layout,
        isCollapse = _this$$store$state$th.isCollapse;
      var asideBrColor = '';
      layout === 'classic' || layout === 'columns' ? asideBrColor = 'layout-el-aside-br-color' : '';
      if (layout === 'columns') {
        // 分栏布局，菜单收起时宽度给 1px / 暂为0px
        if (isCollapse) {
          return ['layout-aside-width1', asideBrColor];
        } else {
          return ['layout-aside-width-default', asideBrColor];
        }
      } else {
        // 其它布局给 64px
        if (isCollapse) {
          return ['layout-aside-width1', asideBrColor];
        } else {
          return ['layout-aside-width-default', asideBrColor];
        }
      }
    },
    // 设置 logo 是否显示
    setShowLogo: function setShowLogo() {
      var _this$$store$state$th2 = this.$store.state.themeConfig.themeConfig,
        layout = _this$$store$state$th2.layout,
        isShowLogo = _this$$store$state$th2.isShowLogo;
      return isShowLogo && layout === 'defaults' || isShowLogo && layout === 'columns';
    },
    // 获取布局配置信息
    getThemeConfig: function getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    }
  },
  mounted: function mounted() {
    // this.getMenus()
  },
  created: function created() {
    var _this = this;
    this.initMenuFixed(document.body.clientWidth);
    this.setFilterRoutes();
    this.bus.$on('layoutMobileResize', function (res) {
      _this.initMenuFixed(res.clientWidth);
    });
    this.bus.$on('oneCatName', function (name) {
      _this.catName = name;
    });
    // 菜单滚动条监听
    this.bus.$on('updateElScrollBar', function () {
      setTimeout(function () {
        _this.$refs.layoutAsideRef.update();
      }, 300);
    });
    if (this.$store.state.themeConfig.themeConfig.layout !== 'columns') {
      this.bus.$on('routesListChange', function () {
        _this.setFilterRoutes();
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.bus.$off('routesListChange');
  },
  methods: {
    getMenus: function getMenus() {
      this.$store.dispatch('user/getMenus');
    },
    // 设置/过滤路由（非静态路由/是否显示在菜单中）
    setFilterRoutes: function setFilterRoutes() {
      if (this.$store.state.themeConfig.themeConfig.layout === 'columns') return false;
      this.$store.commit('user/childMenuList', this.filterRoutesFun(this.$store.state.user.menuList));
    },
    // 设置/过滤路由 递归函数
    filterRoutesFun: function filterRoutesFun(arr) {
      var _this2 = this;
      return arr.filter(function (item) {
        return item.path;
      }).map(function (item) {
        item = Object.assign({}, item);
        if (item.children) item.children = _this2.filterRoutesFun(item.children);
        return item;
      });
    },
    // 设置菜单导航是否固定（移动端）
    initMenuFixed: function initMenuFixed(clientWidth) {
      this.clientWidth = clientWidth;
      this.$emit('routesListChange');
    }
  },
  // 页面销毁时
  destroyed: function destroyed() {
    // 取消菜单滚动条监听
    this.bus.$off('updateElScrollBar', function () {});
  }
};