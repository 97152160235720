var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, "label-width": "80px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "角色名称：",
                prop: "roleName",
                rules: [
                  {
                    required: true,
                    message: "请填写角色名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "身份名称" },
                model: {
                  value: _vm.pram.roleName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.pram,
                      "roleName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "pram.roleName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "状态：" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "开启",
                  "inactive-text": "关闭",
                  "active-value": true,
                  "inactive-value": false,
                },
                model: {
                  value: _vm.pram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "status", $$v)
                  },
                  expression: "pram.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "菜单权限：" } },
            [
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeExpand($event, "menu")
                    },
                  },
                  model: {
                    value: _vm.menuExpand,
                    callback: function ($$v) {
                      _vm.menuExpand = $$v
                    },
                    expression: "menuExpand",
                  },
                },
                [_vm._v("展开/折叠")]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeConnect($event, "menu")
                    },
                  },
                  model: {
                    value: _vm.menuCheckStrictly,
                    callback: function ($$v) {
                      _vm.menuCheckStrictly = $$v
                    },
                    expression: "menuCheckStrictly",
                  },
                },
                [_vm._v("父子联动")]
              ),
              _vm._v(" "),
              _c("el-tree", {
                ref: "menu",
                staticClass: "tree-border",
                attrs: {
                  data: _vm.menuOptions,
                  "show-checkbox": "",
                  "node-key": "id",
                  "default-expand-all": _vm.expandAll,
                  "check-strictly": !_vm.menuCheckStrictly,
                  "empty-text": "加载中，请稍候",
                  props: _vm.defaultProps,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:admin:role:update"],
                  expression: "['merchant:admin:role:update']",
                },
              ],
              attrs: { loading: _vm.loading, size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("pram")
                },
              },
            },
            [_vm._v(_vm._s(_vm.isCreate === 0 ? "确定" : "更新"))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }