"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var systemSetting = _interopRequireWildcard(require("@/api/systemSetting"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'editPrintReceipt',
  props: {
    isEdit: {
      type: Boolean,
      required: true,
      default: false
    },
    editData: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data: function data() {
    return {
      editDataLocal: {
        id: 0,
        // 编辑时大于0
        merId: 0,
        printType: 0,
        // 0=易联云 1=飞蛾云
        printName: '',
        printYlyAppid: '',
        printYlyUserid: '',
        printYlySec: '',
        printYlyMerchineNo: '',
        printFeName: '',
        printFeUser: '',
        printFeUkey: '',
        printFeSn: '',
        status: 0 // 0未启用 1=启用
      }
    };
  },
  created: function created() {
    this.initData();
  },
  methods: {
    close: function close() {
      this.$emit('closeDialog');
    },
    initData: function initData() {
      if (this.isEdit) {
        var _this$editData = this.editData,
          id = _this$editData.id,
          merId = _this$editData.merId,
          printType = _this$editData.printType,
          printName = _this$editData.printName,
          printYlyAppid = _this$editData.printYlyAppid,
          printYlyUserid = _this$editData.printYlyUserid,
          printYlySec = _this$editData.printYlySec,
          printYlyMerchineNo = _this$editData.printYlyMerchineNo,
          printFeName = _this$editData.printFeName,
          printFeUser = _this$editData.printFeUser,
          printFeUkey = _this$editData.printFeUkey,
          printFeSn = _this$editData.printFeSn,
          status = _this$editData.status;
        this.editDataLocal.id = id;
        this.editDataLocal.merId = merId;
        this.editDataLocal.printType = printType;
        this.editDataLocal.printName = printName;
        this.editDataLocal.printYlyAppid = printYlyAppid;
        this.editDataLocal.printYlyUserid = printYlyUserid;
        this.editDataLocal.printYlySec = printYlySec;
        this.editDataLocal.printYlyMerchineNo = printYlyMerchineNo;
        this.editDataLocal.printFeName = printFeName;
        this.editDataLocal.printFeUser = printFeUser;
        this.editDataLocal.printFeUkey = printFeUkey;
        this.editDataLocal.printFeUkey = printFeUkey;
        this.editDataLocal.printFeSn = printFeSn;
        this.editDataLocal.status = status;
      }
    },
    handleSubmitClickUp: function handleSubmitClickUp(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this.isEdit) {
            _this.handledEditPrintConfig(_this.editDataLocal);
          } else {
            _this.handledAddPrintConfig(_this.editDataLocal);
          }
        } else {
          return false;
        }
      });
    },
    handledAddPrintConfig: function handledAddPrintConfig(param) {
      var _this2 = this;
      systemSetting.merchantPrintSave(param).then(function (data) {
        _this2.$message.success('新增成功');
        _this2.handledCloseDia();
      });
    },
    handledEditPrintConfig: function handledEditPrintConfig(param) {
      var _this3 = this;
      systemSetting.merchantPrintEdit(param).then(function (data) {
        _this3.$message.success('编辑成功');
        _this3.handledCloseDia();
      });
    },
    handledCloseDia: function handledCloseDia() {
      this.$emit('unVisible');
    }
  }
};