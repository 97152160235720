var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("pages-header", {
        ref: "pageHeader",
        attrs: {
          title: _vm.$route.params.id !== "0" ? "编辑产品" : "添加产品",
          backUrl: "/product/list",
        },
      }),
      _vm._v(" "),
      _vm.isCopy
        ? _c(
            "el-card",
            {
              staticClass: "mt14",
              attrs: { shadow: "never", bordered: false },
            },
            [
              _c(
                "div",
                { staticClass: "line-ht mb15" },
                [
                  _vm._v(
                    "\n      生成的产品默认是没有上架的，请手动上架产品！\n      "
                  ),
                  _vm.copyConfig.copyType && _vm.copyConfig.copyType == 1
                    ? _c("span", [
                        _vm._v(
                          "您当前剩余" +
                            _vm._s(_vm.copyConfig.copyNum) +
                            "条采集次数。\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.copyConfig.copyType && _vm.copyConfig.copyType != 1
                    ? _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            underline: false,
                            href: "https://help.crmeb.net/crmeb_java/2103903",
                            target: "_blank",
                          },
                        },
                        [_vm._v("如何配置密钥\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n      产品采集设置：设置 > 系统设置 > 第三方接口设置 >\n      采集产品配置（如配置一号通采集，请先登录一号通账号，无一号通，请选择99Api设置）\n    "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.copyConfig.copyType && _vm.copyConfig.copyType != 1
                ? _c(
                    "div",
                    { staticClass: "mb15" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form,
                            callback: function ($$v) {
                              _vm.form = $$v
                            },
                            expression: "form",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("淘宝"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("京东"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 3 } }, [
                            _vm._v("苏宁"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 4 } }, [
                            _vm._v("拼多多"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 5 } }, [
                            _vm._v("天猫"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.copyConfig.copyType
                ? _c(
                    "div",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "selWidth100",
                          attrs: {
                            placeholder: "请输入链接地址",
                            size: "small",
                          },
                          model: {
                            value: _vm.url,
                            callback: function ($$v) {
                              _vm.url =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "url",
                          },
                        },
                        [
                          _c("el-button", {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: [
                                  "merchant:product:copy:product",
                                  "merchant:product:import:product",
                                ],
                                expression:
                                  "['merchant:product:copy:product', 'merchant:product:import:product']",
                              },
                            ],
                            attrs: {
                              slot: "append",
                              icon: "el-icon-search",
                              size: "small",
                            },
                            on: { click: _vm.addProduct },
                            slot: "append",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs",
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            _vm._l(_vm.headTab, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.tit, name: item.name },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                },
              ],
              ref: "formValidate",
              staticClass: "formValidate form-boder-padding",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "108px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "1",
                      expression: "currentTab === '1'",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品类型：", required: "" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "from-ipt-width el-input el-input--small",
                              attrs: { maxlength: "249", disabled: "" },
                            },
                            [
                              _c("span", { staticClass: "el-input__inner" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("productTpyeFilter")(
                                        _vm.formValidate.type
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "from-tips" }, [
                            _vm._v(
                              "云盘产品、卡密产品不支持售后，一经出售，概不退换"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              maxlength: "249",
                              placeholder: "请输入产品名称",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商户产品分类：", prop: "cateIds" } },
                        [
                          _c("el-cascader", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              options: _vm.merProductClassify,
                              props: _vm.props2,
                              clearable: "",
                              "show-all-levels": false,
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.cateIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "cateIds", $$v)
                              },
                              expression: "formValidate.cateIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "平台产品分类：",
                            prop: "categoryId",
                          },
                        },
                        [
                          _c("el-cascader", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              options: _vm.productClassify,
                              props: _vm.props1,
                              filterable: "",
                              clearable: "",
                              "show-all-levels": false,
                              disabled: _vm.isDisabled,
                            },
                            on: { change: _vm.onChangeCategory },
                            model: {
                              value: _vm.formValidate.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "categoryId", $$v)
                              },
                              expression: "formValidate.categoryId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "品牌：", prop: "brandId" } },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "selectLoadMore",
                                  rawName: "v-selectLoadMore",
                                  value: _vm.selectLoadMore,
                                  expression: "selectLoadMore",
                                },
                              ],
                              staticClass: "from-ipt-width",
                              attrs: {
                                clearable: "",
                                filterable: "",
                                loading: _vm.loading,
                                remote: "",
                                disabled:
                                  _vm.isDisabled ||
                                  !_vm.formValidate.categoryId,
                                "remote-method": _vm.remoteMethod,
                                placeholder: "请选择品牌",
                              },
                              model: {
                                value: _vm.formValidate.brandId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "brandId", $$v)
                                },
                                expression: "formValidate.brandId",
                              },
                            },
                            _vm._l(_vm.brandList, function (user) {
                              return _c("el-option", {
                                key: user.id,
                                attrs: { label: user.name, value: user.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位：", prop: "unitName" } },
                        [
                          _c("el-input", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              placeholder: "请输入单位",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.unitName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "unitName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.unitName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "ERP物料号：", prop: "materialCode" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              placeholder: "请输入ERP物料号",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.materialCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "materialCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.materialCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "ERP版本号：", prop: "versionCode" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              placeholder: "请输入ERP版本号",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.versionCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "versionCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.versionCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.type !== 5 && _vm.formValidate.type !== 6
                    ? _c(
                        "el-col",
                        { attrs: { xs: 18, sm: 18, md: 18, lg: 12, xl: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "运费模板：", prop: "tempId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "from-ipt-width mr20",
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled: _vm.isDisabled,
                                  },
                                  model: {
                                    value: _vm.formValidate.tempId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formValidate, "tempId", $$v)
                                    },
                                    expression: "formValidate.tempId",
                                  },
                                },
                                _vm._l(_vm.shippingTemplates, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品关键字：" } },
                        [
                          _c("keyword", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              labelarr: _vm.labelarr,
                              isDisabled: _vm.isDisabled,
                            },
                            on: { getLabelarr: _vm.getLabelarr },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "from-tips" }, [
                            _vm._v("用户可以根据关键字进行产品搜索"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品简介：", prop: "intro" } },
                        [
                          _c("el-input", {
                            staticClass: "from-ipt-width",
                            attrs: {
                              type: "textarea",
                              maxlength: "250",
                              rows: 3,
                              placeholder: "请输入产品简介",
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.formValidate.intro,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "intro",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.intro",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "from-tips" }, [
                            _vm._v("通过公众号分享产品详情，会展示此简介信息"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品封面图：", prop: "image" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox acea-row",
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.modalPicTap("1")
                                },
                              },
                            },
                            [
                              _vm.formValidate.image
                                ? _c("div", { staticClass: "pictrue" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formValidate.image },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upLoad" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-camera cameraIconfont",
                                    }),
                                  ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isDisabled,
                                  expression: "!isDisabled",
                                },
                              ],
                              staticClass: "from-tips",
                            },
                            [_vm._v("建议尺寸：800*800px，上传小于500kb的图片")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "产品轮播图：",
                            prop: "sliderImages",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.sliderImages,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "pictrue",
                                      attrs: { draggable: "true" },
                                      on: {
                                        dragstart: function ($event) {
                                          return _vm.handleDragStart(
                                            $event,
                                            item
                                          )
                                        },
                                        dragover: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleDragOver(
                                            $event,
                                            item
                                          )
                                        },
                                        dragenter: function ($event) {
                                          return _vm.handleDragEnter(
                                            $event,
                                            item
                                          )
                                        },
                                        dragend: function ($event) {
                                          return _vm.handleDragEnd($event, item)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", { attrs: { src: item } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.isDisabled,
                                            expression: "!isDisabled",
                                          },
                                        ],
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.sliderImages.length < 10 &&
                              !_vm.isDisabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "upLoadPicBox",
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalPicTap("2")
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "upLoad" }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "from-tips" }, [
                            _vm._v(
                              "建议尺寸：800*800px，上传小于500kb的图片；最多可上传10张图片，拖动可调整顺序"
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "保障服务：" } },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row row-middle mb5" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "from-ipt-width",
                                  attrs: {
                                    placeholder: "请选择保障服务",
                                    clearable: "",
                                    filterable: "",
                                    multiple: _vm.multiples,
                                    disabled: _vm.isDisabled,
                                  },
                                  on: { change: _vm.changeGuarantee },
                                  model: {
                                    value: _vm.formValidate.guaranteeIdsList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formValidate,
                                        "guaranteeIdsList",
                                        $$v
                                      )
                                    },
                                    expression: "formValidate.guaranteeIdsList",
                                  },
                                },
                                _vm._l(
                                  _vm.guaranteeNew,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c("el-switch", {
                                staticClass: "ml10",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  "active-value": true,
                                  "inactive-value": false,
                                  "active-text": "多选",
                                  "inactive-text": "单选",
                                },
                                on: { change: _vm.onchangeIsShow },
                                model: {
                                  value: _vm.isShowGroup,
                                  callback: function ($$v) {
                                    _vm.isShowGroup = $$v
                                  },
                                  expression: "isShowGroup",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.guaranteeName, function (item, index) {
                            return _c(
                              "el-tag",
                              { key: index, staticClass: "mr10" },
                              [_vm._v(_vm._s(item))]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    _vm._b({}, "el-col", _vm.grid2, false),
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主图视频：", prop: "video_link" } },
                        [
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "from-ipt-width",
                                  attrs: {
                                    disabled: _vm.isDisabled,
                                    size: "small",
                                    placeholder: "请输入MP4格式的视频链接",
                                  },
                                  model: {
                                    value: _vm.videoLink,
                                    callback: function ($$v) {
                                      _vm.videoLink = $$v
                                    },
                                    expression: "videoLink",
                                  },
                                },
                                [
                                  _c("input", {
                                    ref: "refid",
                                    staticStyle: { display: "none" },
                                    attrs: { type: "file" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        slot: "append",
                                        action: "",
                                        "http-request": _vm.handleUploadForm,
                                        "before-upload": _vm.beforeAvatarUpload,
                                        headers: _vm.myHeaders,
                                        "show-file-list": false,
                                        disabled: _vm.isDisabled,
                                        multiple: "",
                                      },
                                      slot: "append",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            disabled: _vm.isDisabled,
                                            size: "small",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.videoLink
                                                  ? "确认添加"
                                                  : "上传视频"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "from-tips" }, [
                            _vm._v("请上传小于20M的视频"),
                          ]),
                          _vm._v(" "),
                          _vm.videoLink
                            ? _c("div", { staticClass: "iview-video-style" }, [
                                _c(
                                  "video",
                                  {
                                    staticClass: "from-ipt-width",
                                    staticStyle: {
                                      height: "100% !important",
                                      "border-radius": "10px",
                                    },
                                    attrs: {
                                      src: _vm.videoLink,
                                      controls: "controls",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                您的浏览器不支持 video 标签。\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mark" }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-delete iconv",
                                  on: { click: _vm.delVideo },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "2",
                      expression: "currentTab === '2'",
                    },
                  ],
                  attrs: { gutter: 24 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品规格：", props: "specType" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeSpec(
                                    _vm.formValidate.specType
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.specType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "specType", $$v)
                                },
                                expression: "formValidate.specType",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: false },
                                },
                                [_vm._v("单规格")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("多规格"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "佣金设置：", props: "isSub" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangetype(
                                    _vm.formValidate.isSub
                                  )
                                },
                              },
                              model: {
                                value: _vm.formValidate.isSub,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formValidate, "isSub", $$v)
                                },
                                expression: "formValidate.isSub",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "radio",
                                  attrs: { label: true },
                                },
                                [_vm._v("单独设置")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("默认设置"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formValidate.specType && !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择规格：", prop: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "acea-row" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "600px" },
                                      model: {
                                        value: _vm.formValidate.selectRule,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formValidate,
                                            "selectRule",
                                            $$v
                                          )
                                        },
                                        expression: "formValidate.selectRule",
                                      },
                                    },
                                    _vm._l(_vm.ruleList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.ruleName,
                                          value: item.id,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml20",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.confirm },
                                    },
                                    [_vm._v("确认")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.addRule } },
                                    [_vm._v("添加规格")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.formValidate.attr.length
                            ? _c(
                                "el-form-item",
                                _vm._l(
                                  _vm.formValidate.attr,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        { staticClass: "acea-row row-middle" },
                                        [
                                          _c("span", { staticClass: "mr5" }, [
                                            _vm._v(_vm._s(item.attrName)),
                                          ]),
                                          _c("i", {
                                            staticClass: "el-icon-circle-close",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleRemoveAttr(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "rulesBox" },
                                        [
                                          _vm._l(
                                            item.attrValue,
                                            function (j, indexn) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: indexn,
                                                  staticClass: "mb5 mr10",
                                                  attrs: {
                                                    closable: "",
                                                    size: "medium",
                                                    "disable-transitions": false,
                                                  },
                                                  on: {
                                                    close: function ($event) {
                                                      return _vm.handleClose(
                                                        item.attrValue,
                                                        indexn
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(j) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          item.inputVisible
                                            ? _c("el-input", {
                                                ref: "saveTagInput",
                                                refInFor: true,
                                                staticClass: "input-new-tag",
                                                attrs: {
                                                  placeholder: "按回车键添加",
                                                  size: "small",
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "enter",
                                                        13,
                                                        $event.key,
                                                        "Enter"
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    return _vm.createAttr(
                                                      item.attrValue.attrsVal,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    item.attrValue.attrsVal,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item.attrValue,
                                                      "attrsVal",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.attrValue.attrsVal",
                                                },
                                              })
                                            : _c(
                                                "el-button",
                                                {
                                                  staticClass: "button-new-tag",
                                                  attrs: { size: "small" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showInput(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("+ 添加")]
                                              ),
                                        ],
                                        2
                                      ),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "规格：" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "255px" },
                                    attrs: { placeholder: "请输入规格" },
                                    model: {
                                      value: _vm.formDynamic.attrsName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formDynamic,
                                          "attrsName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formDynamic.attrsName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "规格值：",
                                    "label-width": "90px",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "254px" },
                                    attrs: { placeholder: "请输入规格值" },
                                    model: {
                                      value: _vm.formDynamic.attrsVal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formDynamic,
                                          "attrsVal",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "formDynamic.attrsVal",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "0px" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml20",
                                      attrs: { size: "small", type: "primary" },
                                      on: { click: _vm.createAttrName },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: { click: _vm.offAttrName },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formValidate.attr.length > 0 &&
                  _vm.formValidate.specType &&
                  !_vm.isDisabled
                    ? _c(
                        "el-col",
                        { staticClass: "noForm", attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "批量设置：" } },
                            [
                              _c("el-alert", {
                                attrs: {
                                  title: "价格设置范围 0~999999.99",
                                  type: "info",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.oneFormBatch,
                                    border: "",
                                    size: "small",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "图片",
                                      width: "80",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3302088318
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "一级返佣(%)",
                                          "min-width": "120",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      maxlength: "6",
                                                      min: "0",
                                                      max: scope.row.price,
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.keyupEventBrokerage(
                                                          "brokerage",
                                                          scope.$index,
                                                          1
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.brokerage,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "brokerage",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.brokerage",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          636120217
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "二级返佣(%)",
                                          "min-width": "120",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      maxlength: "6",
                                                      min: "0",
                                                      max: scope.row.price,
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.keyupEventBrokerage(
                                                          "brokerageTwo",
                                                          scope.$index,
                                                          1
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.brokerageTwo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "brokerageTwo",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.brokerageTwo",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2941795481
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        "min-width": "120",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    maxlength: "9",
                                                    min: "0",
                                                    max: "999999",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub ? void 0 : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "el-table-column",
                                    {
                                      attrs: {
                                        label: "操作",
                                        width: "70",
                                        align: "center",
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "submission",
                                            on: { click: _vm.batchAdd },
                                          },
                                          [_vm._v("批量添加")]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                    [
                      _vm.formValidate.specType === false
                        ? _c(
                            "el-form-item",
                            [
                              _c("el-alert", {
                                attrs: {
                                  title: "价格设置范围 0~999999.99",
                                  type: "info",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.OneattrValue,
                                    border: "",
                                    size: "small",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "图片",
                                      width: "77",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "dan",
                                                        "pi"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1809244089
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "一级返佣(%)",
                                            "min-width": "120",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        maxlength: "6",
                                                        disabled:
                                                          _vm.isDisabled,
                                                        min: "0",
                                                      },
                                                      nativeOn: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          return _vm.keyupEventBrokerage(
                                                            "brokerage",
                                                            scope.$index,
                                                            2
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row.brokerage,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerage",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerage",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4137747993
                                          ),
                                        }),
                                        _vm._v(" "),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "二级返佣(%)",
                                            "min-width": "120",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("el-input", {
                                                      staticClass: "priceBox",
                                                      attrs: {
                                                        maxlength: "6",
                                                        disabled:
                                                          _vm.isDisabled,
                                                        min: "0",
                                                      },
                                                      nativeOn: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          return _vm.keyupEventBrokerage(
                                                            "brokerageTwo",
                                                            scope.$index,
                                                            2
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row
                                                            .brokerageTwo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "brokerageTwo",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.brokerageTwo",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            4066313593
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        "min-width": "120",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    maxlength: "9",
                                                    min: "0",
                                                    type: "test",
                                                    readonly: false,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        2
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.type == 5
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "云盘设置",
                                            "min-width": "120",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    !scope.row.expand
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "small",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAddVirtually(
                                                                  0,
                                                                  "OneattrValue",
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "添加链接\n                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "el-button",
                                                          {
                                                            staticClass:
                                                              "seeCatMy pointer",
                                                            attrs: {
                                                              type: "text",
                                                              size: "small",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.seeVirtually(
                                                                  _vm
                                                                    .OneattrValue[0],
                                                                  "OneattrValue",
                                                                  0,
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "已设置\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            144154718
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formValidate.type == 6
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "卡密设置",
                                            "min-width": "120",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    !scope.row.cdkeyId
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "small",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAddVirtually(
                                                                  0,
                                                                  "OneattrValue",
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "添加卡密\n                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "el-button",
                                                          {
                                                            staticClass:
                                                              "seeCatMy pointer",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.seeVirtually(
                                                                  _vm
                                                                    .OneattrValue[0],
                                                                  "OneattrValue",
                                                                  0,
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .cdkeyLibraryName
                                                              ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3022906233
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$route.params.id && _vm.showAll
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "全部sku：" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "default",
                                    disabled: _vm.isDisabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAllSku()
                                    },
                                  },
                                },
                                [_vm._v("展示")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.attr.length > 0 &&
                      _vm.formValidate.specType
                        ? _c(
                            "el-form-item",
                            {
                              staticClass: "labeltop",
                              class: _vm.isDisabled
                                ? "disLabel"
                                : "disLabelmoren",
                              attrs: { label: "产品属性：" },
                            },
                            [
                              _c("el-alert", {
                                attrs: {
                                  title: "价格设置范围 0~999999.99",
                                  type: "info",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticClass: "tabNumWidth",
                                  attrs: {
                                    data: _vm.ManyAttrValue,
                                    border: "",
                                    size: "small",
                                  },
                                },
                                [
                                  _vm.manyTabDate
                                    ? _vm._l(
                                        _vm.manyTabDate,
                                        function (item, iii) {
                                          return _c("el-table-column", {
                                            key: iii,
                                            attrs: {
                                              label: _vm.manyTabTit[iii].title,
                                              "min-width": "80",
                                              align: "center",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("span", {
                                                        staticClass: "priceBox",
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            scope.row[iii]
                                                          ),
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "图片",
                                      width: "80",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "upLoadPicBox",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.modalPicTap(
                                                        "1",
                                                        "duo",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  scope.row.image
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "pictrue tabPic",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: scope.row
                                                                .image,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "upLoad tabPic",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-camera cameraIconfont",
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4035746379
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "一级返佣(%)",
                                          "min-width": "120",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      disabled: _vm.isDisabled,
                                                      maxlength: "6",
                                                      min: "0",
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.keyupEventBrokerage(
                                                          "brokerage",
                                                          scope.$index,
                                                          3
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.brokerage,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "brokerage",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.brokerage",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          408932920
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "二级返佣(%)",
                                          "min-width": "120",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input", {
                                                    staticClass: "priceBox",
                                                    attrs: {
                                                      maxlength: "6",
                                                      disabled: _vm.isDisabled,
                                                      min: "0",
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.keyupEventBrokerage(
                                                          "brokerageTwo",
                                                          scope.$index,
                                                          3
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.brokerageTwo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "brokerageTwo",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.brokerageTwo",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3477016888
                                        ),
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.attrValue, function (item, iii) {
                                    return _c("el-table-column", {
                                      key: iii,
                                      attrs: {
                                        label: _vm.formThead[iii].title,
                                        "min-width": "120",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("el-input", {
                                                  staticClass: "priceBox",
                                                  attrs: {
                                                    disabled: _vm.isDisabled,
                                                    maxlength: "9",
                                                    min: "0",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.keyupEvent(
                                                        iii,
                                                        scope.row[iii],
                                                        scope.$index,
                                                        3
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: scope.row[iii],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        iii,
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[iii]",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }),
                                  _vm._v(" "),
                                  _vm.formValidate.isSub ? void 0 : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formValidate.type == 5
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "云盘设置",
                                            "min-width": "120",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    !scope.row.expand
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "small",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAddVirtually(
                                                                  scope.$index,
                                                                  "ManyAttrValue",
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "添加链接\n                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "el-button",
                                                          {
                                                            staticClass:
                                                              "seeCatMy pointer",
                                                            attrs: {
                                                              type: "text",
                                                              size: "small",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.seeVirtually(
                                                                  _vm
                                                                    .ManyAttrValue[
                                                                    scope.$index
                                                                  ],
                                                                  "ManyAttrValue",
                                                                  scope.$index,
                                                                  1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "已设置\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3702740335
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.formValidate.type == 6
                                    ? [
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "卡密设置",
                                            "min-width": "120",
                                            align: "center",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    !scope.row.cdkeyId
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "small",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleAddVirtually(
                                                                  scope.$index,
                                                                  "ManyAttrValue",
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "添加卡密\n                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "el-button",
                                                          {
                                                            staticClass:
                                                              "seeCatMy pointer",
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.seeVirtually(
                                                                  _vm
                                                                    .ManyAttrValue[
                                                                    scope.$index
                                                                  ],
                                                                  "ManyAttrValue",
                                                                  scope.$index,
                                                                  2
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .cdkeyLibraryName
                                                              ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2756674472
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.isDisabled
                                    ? _c("el-table-column", {
                                        key: "3",
                                        attrs: {
                                          label: "操作",
                                          width: "70",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass: "submission",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.delAttrTable(
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          458093457
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "3" && !_vm.isDisabled,
                      expression: "currentTab === '3' && !isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品详情：" } },
                        [
                          _c("Tinymce", {
                            model: {
                              value: _vm.formValidate.content,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "content",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formValidate.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "3" && _vm.isDisabled,
                      expression: "currentTab === '3' && isDisabled",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品详情：" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.formValidate.content || "无"),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "4",
                      expression: "currentTab === '4'",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序：" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 1,
                          max: 9999,
                          placeholder: "请输入排序",
                          disabled: _vm.isDisabled,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.proving1($event)
                          },
                        },
                        model: {
                          value: _vm.formValidate.sort,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formValidate,
                              "sort",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formValidate.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "acea-row" },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "proCoupon",
                          attrs: { label: "赠送优惠券：" },
                        },
                        [
                          _c("div", { staticClass: "from-tips mb14" }, [
                            _vm._v("用户购买产品后赠送的优惠券"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "acea-row" },
                            [
                              _vm._l(
                                _vm.formValidate.coupons,
                                function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticClass: "mr10 mb10",
                                      attrs: {
                                        closable: !_vm.isDisabled,
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleCloseCoupon(tag)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(tag.name) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm.formValidate.couponIds == null
                                ? _c("span", { staticClass: "mr15" }, [
                                    _vm._v("无"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isDisabled
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "mr15",
                                      attrs: { size: "small" },
                                      on: { click: _vm.addCoupon },
                                    },
                                    [_vm._v("选择优惠券")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  Number(_vm.currentTab) > 1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "submission priamry_border",
                          on: { click: _vm.handleSubmitUp },
                        },
                        [_vm._v("上一步\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: Number(_vm.currentTab) < 4,
                          expression: "Number(currentTab) < 4",
                        },
                      ],
                      staticClass: "priamry_border",
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmitNest("formValidate")
                        },
                      },
                    },
                    [_vm._v("下一步\n        ")]
                  ),
                  _vm._v(" "),
                  _vm.checkPermi(["merchant:product:update"])
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                (_vm.currentTab === "3" ||
                                  _vm.$route.params.id) &&
                                !_vm.isDisabled,
                              expression:
                                "(currentTab === '3' || $route.params.id) && !isDisabled",
                            },
                          ],
                          staticClass: "submission",
                          attrs: { type: "primary", loading: _vm.loadingBtn },
                          on: {
                            click: function ($event) {
                              return _vm.handleSubmit("formValidate")
                            },
                          },
                        },
                        [_vm._v("提交\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-cloud-disk", {
        ref: "cloudDiskRef",
        attrs: { cloudDisk: _vm.cloudDisk },
        on: {
          handlerSubSuccess: _vm.handlerSubSuccessCloudDisk,
          closeCloudDisk: _vm.closeCloudDisk,
        },
      }),
      _vm._v(" "),
      _c("cdkey-library", {
        ref: "cdkeyLibrary",
        attrs: { cdkeyLibraryInfo: _vm.cdkeyLibraryInfo },
        on: { handlerSubSuccess: _vm.handlerChangeCdkeyIdSubSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }