var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: { model: _vm.editPram, "label-width": "90px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分类名称：",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入分类名称",
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  maxlength: _vm.biztype.value === 1 ? 8 : 20,
                  placeholder: "分类名称",
                },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "父级：" } },
            [
              _c("el-cascader", {
                ref: "cascader",
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.isCreate === 1 && _vm.editPram.pid === 0,
                  options: _vm.allTreeList,
                  props: _vm.categoryProps,
                },
                on: { change: _vm.change },
                model: {
                  value: _vm.editPram.pid,
                  callback: function ($$v) {
                    _vm.$set(_vm.editPram, "pid", $$v)
                  },
                  expression: "editPram.pid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.biztype.value === 5
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单图标：" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请选择菜单图标" },
                      model: {
                        value: _vm.editPram.icon,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.editPram,
                            "icon",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "editPram.icon",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "append",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.addIcon },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "分类图标：" } }, [
            _c(
              "div",
              {
                staticClass: "upLoadPicBox",
                on: {
                  click: function ($event) {
                    return _vm.modalPicTap("1")
                  },
                },
              },
              [
                _vm.editPram.icon
                  ? _c("div", { staticClass: "pictrue" }, [
                      _c("img", { attrs: { src: _vm.editPram.icon } }),
                    ])
                  : _c("div", { staticClass: "upLoad" }, [
                      _c("i", { staticClass: "el-icon-camera cameraIconfont" }),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "from-tips" }, [
                  _vm._v("建议尺寸(180*180)"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "排序：" } },
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 9999 },
                model: {
                  value: _vm.editPram.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.biztype.value !== 1 &&
          _vm.biztype.value !== 3 &&
          _vm.biztype.value !== 5
            ? _c(
                "el-form-item",
                { attrs: { label: "扩展字段：" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "扩展字段" },
                    model: {
                      value: _vm.editPram.extra,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editPram,
                          "extra",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editPram.extra",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:product:category:update"],
                  expression: "['merchant:product:category:update']",
                },
              ],
              attrs: { type: "primary", loading: _vm.loadingBtn },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("editPram")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }