var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailSection padBox" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formValidate",
          staticClass: "formValidate",
          attrs: {
            model: _vm.formValidate,
            "label-width": "99px",
            rules: _vm.rules,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          !_vm.stockEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "商户产品分类：", prop: "cateIds" } },
                [
                  _c("el-cascader", {
                    staticClass: "from-ipt-width",
                    attrs: {
                      options: _vm.merProductClassify,
                      props: _vm.props2,
                      clearable: "",
                      "show-all-levels": false,
                    },
                    model: {
                      value: _vm.formValidate.cateIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "cateIds", $$v)
                      },
                      expression: "formValidate.cateIds",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticClass: "labeltop", attrs: { label: "产品属性：" } },
            [
              _c(
                "el-table",
                {
                  staticClass: "tabNumWidth",
                  attrs: { data: _vm.ManyAttrValue, size: "small" },
                },
                [
                  _vm.manyTabDate
                    ? _vm._l(_vm.manyTabDate, function (item, iii) {
                        return _c("el-table-column", {
                          key: iii,
                          attrs: { label: _vm.manyTabTit[iii].title },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", {
                                      staticClass: "priceBox",
                                      domProps: {
                                        textContent: _vm._s(scope.row[iii]),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "line-heightOne" }, [
                              scope.row.image
                                ? _c(
                                    "div",
                                    { staticClass: "tabPic line-heightOne" },
                                    [
                                      _c("img", {
                                        attrs: { src: scope.row.image },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.attrValue, function (item, iii) {
                    return _c("el-table-column", {
                      key: iii,
                      attrs: { label: _vm.formThead[iii].title },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                (_vm.formThead[iii].title === "售价（元）" ||
                                  _vm.formThead[iii].title === "库存") &&
                                !_vm.stockEdit
                                  ? _c("el-input", {
                                      staticClass: "priceBox",
                                      attrs: { type: "number", min: 0 },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.keyupEvent(
                                            iii,
                                            scope.row[iii],
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row[iii],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            iii,
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "scope.row[iii]",
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(_vm._s(scope.row[iii])),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _vm._v(" "),
                  _vm.stockEdit && _vm.productType !== 6
                    ? _c("el-table-column", {
                        attrs: {
                          label: "增加库存",
                          align: "right",
                          "min-width": "120",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { staticClass: "all line-heightOne" },
                                    [
                                      _c("el-input-number", {
                                        staticClass: "priceBox",
                                        attrs: {
                                          step: 1,
                                          "step-strictly": "",
                                          min: 0,
                                        },
                                        model: {
                                          value: scope.row.stockAdd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "stockAdd",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.stockAdd",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2929204899
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.stockEdit && _vm.productType !== 6
                    ? _c("el-table-column", {
                        attrs: { label: "链接设置", align: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-form-item", { staticClass: "all" }, [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.editVirtually(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("编辑链接")]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3905692113
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.productType === 6 &&
                  _vm.checkPermi(["merchant:card:secret:page:list"])
                    ? _c("el-table-column", {
                        attrs: {
                          label: _vm.stockEdit ? "补充卡密" : "管理卡密",
                          align: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-form-item", { staticClass: "all" }, [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerToAddCdkey(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("补充卡密")]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          298575074
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.stockEdit &&
                  _vm.productType === 6 &&
                  _vm.checkPermi(["merchant:card:secret:page:list"])
                    ? _c("el-table-column", {
                        attrs: { label: "卡密设置", align: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-form-item", { staticClass: "all" }, [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerEeditCdkey(
                                              scope.$index,
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.cdkeyLibraryName)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2263660273
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          (_vm.stockEdit && _vm.productType !== 6) || !_vm.stockEdit
            ? _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSubmit("formValidate")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.loadingBtn ? "提交中 ..." : "确 定"))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-cloud-disk", {
        ref: "cloudDiskRef",
        attrs: { cloudDisk: _vm.cloudDisk },
        on: {
          handlerSubSuccess: _vm.handlerSubSuccessCloudDisk,
          closeCloudDisk: _vm.closeCloudDisk,
        },
      }),
      _vm._v(" "),
      _vm.cdkeyLibraryInfo
        ? _c("cdkey-library", {
            ref: "cdkeyLibrary",
            attrs: { cdkeyLibraryInfo: _vm.cdkeyLibraryInfo },
            on: { handlerSubSuccess: _vm.handlerChangeCdkeyIdSubSuccess },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }