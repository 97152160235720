"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemSetting = require("@/api/systemSetting");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'agreeToReturn',
  props: {
    refundInfo: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      listLoading: false,
      addressList: [],
      defaultId: 0,
      loading: false
    };
  },
  mounted: function mounted() {
    if (localStorage.getItem('merchantAddressList')) {
      this.addressList = JSON.parse(localStorage.getItem('merchantAddressList'));
      var data = this.addressList.find(function (item) {
        return item.isDefault;
      });
      this.defaultId = data.id;
    } else {
      this.getList();
    }
  },
  methods: {
    //确定提交
    handleSubmit: function handleSubmit() {
      this.submit();
    },
    // 提交方法
    submit: function submit() {
      var _this = this;
      this.loading = true;
      (0, _order.orderAuditApi)({
        auditType: 'success',
        refundOrderNo: this.refundInfo.refundOrderNo,
        merAddressId: this.defaultId
      }).then(function (res) {
        _this.loading = false;
        _this.$message.success('审核成功');
        _this.$emit('onHandleSuccess');
      }).catch(function (res) {
        _this.loading = false;
      });
    },
    handleCancel: function handleCancel() {
      this.$emit('onHandleCancel');
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _systemSetting.merchantAddressListApi)().then(function (res) {
        _this2.addressList = res;
        if (_this2.addressList.length) {
          localStorage.setItem('merchantAddressList', JSON.stringify(res));
          var data = _this2.addressList.find(function (item) {
            return item.isDefault;
          });
          _this2.defaultId = data.id;
        }
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    //选中地址
    handleChecked: function handleChecked() {}
  }
};