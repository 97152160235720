var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 14 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, md: 24, lg: 12 } },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  attrs: { bordered: false, "dis-hover": "", shadow: "never" },
                },
                [
                  _c("div", { staticClass: "header_title_line" }, [
                    _vm._v("快捷入口"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "nav_grid" },
                    _vm._l(_vm.permList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "nav_grid_item",
                          on: {
                            click: function ($event) {
                              return _vm.navigatorTo(item.url)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pic_badge",
                              style: { backgroundColor: item.bgColor },
                            },
                            [
                              _c("span", {
                                staticClass: "iconfont",
                                class: item.icon,
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(item.title))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:statistics:home:operating:data"],
                  expression: "['merchant:statistics:home:operating:data']",
                },
              ],
              attrs: { xs: 24, sm: 24, md: 24, lg: 12 },
            },
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  attrs: { shadow: "never", bordered: false },
                },
                [
                  _c("div", { staticClass: "header_title_line" }, [
                    _vm._v("经营数据"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "nav_grid" },
                    _vm._l(_vm.businessList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "nav_grid_item",
                          on: {
                            click: function ($event) {
                              return _vm.navigatorTo(item.path)
                            },
                          },
                        },
                        [
                          _c("p", { staticClass: "num_data" }, [
                            _vm._v(_vm._s(item.num || 0)),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "label" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }