"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = require("@/utils/permission");
var _productCdkey = require("@/api/productCdkey");
var _validate = require("@/utils/validate");
var $constants = _interopRequireWildcard(require("@/utils/constants"));
var _public = require("@/libs/public");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var tableFroms = {
  page: 1,
  limit: $constants.page.limit[0],
  name: '',
  productName: ''
};
var _default = exports.default = {
  name: 'cdkey',
  data: function data() {
    return {
      productName: '',
      name: '',
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: Object.assign({}, tableFroms),
      dialogVisible: false,
      formValidate: {
        name: '',
        remark: '',
        id: 0
      },
      loadingBtn: false,
      ruleValidate: {
        name: [{
          required: true,
          message: '请填写卡密库名称',
          trigger: 'blue'
        }]
      }
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['merchant:cdkey:library:page:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //重置
    handleReset: function handleReset() {
      this.tableFrom = Object.assign({}, tableFroms);
      this.productName = '';
      this.name = '';
      this.getList(1);
    },
    // 添加
    handleAdd: function handleAdd(row) {
      if (row) {
        Object.assign(this.formValidate, row);
      } else {
        Object.assign(this.formValidate, {
          name: '',
          remark: '',
          id: 0
        });
      }
      this.dialogVisible = true;
    },
    //提交
    handleSubmit: (0, _validate.Debounce)(function (name) {
      var _this = this;
      this.$refs[name].validate(function (valid) {
        if (valid) {
          _this.loadingBtn = true;
          _this.formValidate.id === 0 ? (0, _productCdkey.productCdkeysaveApi)(_this.formValidate).then(function (res) {
            _this.$message.success('新增成功');
            _this.dialogVisible = false;
            _this.getList(1);
            _this.loadingBtn = false;
          }).catch(function (res) {
            _this.loadingBtn = false;
          }) : (0, _productCdkey.productUnrelatedUpdateApi)(_this.formValidate).then(function (res) {
            _this.$message.success('编辑成功');
            _this.loadingBtn = false;
            _this.dialogVisible = false;
            _this.getList(1);
          }).catch(function (res) {
            _this.loadingBtn = false;
          });
        }
      });
    }),
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.productName = encodeURIComponent(this.productName);
      this.tableFrom.name = encodeURIComponent(this.name);
      (0, _productCdkey.productCdkeyListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      this.$modalSure('确定删除此卡密吗？').then(function () {
        (0, _productCdkey.productCdkeyDeleteApi)(id).then(function () {
          _this3.$message.success('删除成功');
          (0, _public.handleDeleteTable)(_this3.tableData.data.length, _this3.tableFrom);
          _this3.getList('');
        });
      });
    }
  }
};