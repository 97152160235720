var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("pages-header", {
        ref: "pageHeader",
        attrs: {
          title:
            this.$route.params.edit === "edit"
              ? "优惠券编辑"
              : !this.$route.params.id
              ? "优惠券添加"
              : "优惠券复制",
          backUrl: "/coupon/list",
        },
      }),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "150px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "优惠劵名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "from-ipt-width",
                    attrs: {
                      maxlength: "20",
                      placeholder: "请输入优惠券名称",
                      disabled: _vm.$route.params.edit ? true : false,
                    },
                    model: {
                      value: _vm.ruleForm.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠劵类别：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "category", $$v)
                        },
                        expression: "ruleForm.category",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("商家券"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("产品券"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.category === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "产品：", prop: "checked" } },
                    [
                      _c(
                        "div",
                        { staticClass: "acea-row" },
                        [
                          _vm.ruleForm.checked.length
                            ? _vm._l(
                                _vm.ruleForm.checked,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pictrue" },
                                    [
                                      _c("img", { attrs: { src: item.image } }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass: "el-icon-error btndel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemove(index)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                }
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "upLoadPicBox",
                              on: { click: _vm.changeGood },
                            },
                            [
                              _c("div", { staticClass: "upLoad" }, [
                                _c("i", {
                                  staticClass: "el-icon-camera cameraIconfont",
                                }),
                              ]),
                            ]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券面值：", prop: "money" } },
                [
                  _c("el-input-number", {
                    staticClass: "from-ipt-width",
                    attrs: {
                      min: 1,
                      max: 9999,
                      label: "优惠券面值",
                      disabled: _vm.$route.params.edit ? true : false,
                    },
                    model: {
                      value: _vm.ruleForm.money,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "money",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.money",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用门槛：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.threshold,
                        callback: function ($$v) {
                          _vm.threshold = $$v
                        },
                        expression: "threshold",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("无门槛"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("有门槛"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.threshold
                ? _c(
                    "el-form-item",
                    { attrs: { label: "优惠券最低消费：", prop: "minPrice" } },
                    [
                      _c("el-input-number", {
                        staticClass: "iptWfrom-ipt-widthidth",
                        attrs: {
                          step: 1,
                          "step-strictly": "",
                          min: 1,
                          max: 999999,
                          label: "描述文字",
                          disabled: _vm.$route.params.edit ? true : false,
                        },
                        model: {
                          value: _vm.ruleForm.minPrice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "minPrice",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.minPrice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用有效期：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.isFixedTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isFixedTime", $$v)
                        },
                        expression: "ruleForm.isFixedTime",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("天数"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("时间段"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "使用有效期限（天）：", prop: "day" } },
                    [
                      _c("el-input-number", {
                        staticClass: "from-ipt-width",
                        attrs: {
                          min: 0,
                          max: 999,
                          label: "使用有效期限（天）",
                          disabled: _vm.$route.params.edit ? true : false,
                        },
                        model: {
                          value: _vm.ruleForm.day,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "day",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.day",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.ruleForm.isFixedTime
                ? _c(
                    "el-form-item",
                    { attrs: { label: "使用有效期限：", prop: "resource" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "from-ipt-width",
                        attrs: {
                          disabled: _vm.$route.params.edit ? true : false,
                          type: "datetimerange",
                          "range-separator": "-",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "picker-options": _vm.pickerOptions,
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.termTime,
                          callback: function ($$v) {
                            _vm.termTime = $$v
                          },
                          expression: "termTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取是否限时：", prop: "isTimeReceive" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.isTimeReceive,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isTimeReceive", $$v)
                        },
                        expression: "ruleForm.isTimeReceive",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("限时"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不限时"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isTimeReceive
                ? _c(
                    "el-form-item",
                    { attrs: { label: "领取时间：" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "from-ipt-width",
                        attrs: {
                          disabled: _vm.$route.params.edit ? true : false,
                          type: "datetimerange",
                          "range-separator": "-",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions,
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { blur: _vm.handleTimestamp },
                        model: {
                          value: _vm.isForeverTime,
                          callback: function ($$v) {
                            _vm.isForeverTime = $$v
                          },
                          expression: "isForeverTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "领取方式：", prop: "receiveType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.receiveType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "receiveType", $$v)
                        },
                        expression: "ruleForm.receiveType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("手动领取"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("产品买赠券"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "是否限量：", prop: "isLimited" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.isLimited,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "isLimited", $$v)
                        },
                        expression: "ruleForm.isLimited",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("限量"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("不限量"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ruleForm.isLimited
                ? _c(
                    "el-form-item",
                    { attrs: { label: "发布数量：", prop: "total" } },
                    [
                      _c("el-input-number", {
                        staticClass: "from-ipt-width",
                        attrs: {
                          min: 1,
                          max: 9999,
                          disabled: _vm.$route.params.edit ? true : false,
                        },
                        model: {
                          value: _vm.ruleForm.total,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "total",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "ruleForm.total",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "排序：", prop: "sort" } },
                [
                  _c("el-input-number", {
                    staticClass: "from-ipt-width",
                    attrs: {
                      min: 1,
                      max: 9999,
                      label: "排序",
                      disabled: _vm.$route.params.edit ? true : false,
                    },
                    model: {
                      value: _vm.ruleForm.sort,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "sort",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.sort",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态：", prop: "status" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: {
                        disabled: _vm.$route.params.edit ? true : false,
                      },
                      model: {
                        value: _vm.ruleForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "status", $$v)
                        },
                        expression: "ruleForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("开启"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: [
                            "merchant:coupon:save",
                            "merchant:coupon:product:join:edit",
                          ],
                          expression:
                            "['merchant:coupon:save', 'merchant:coupon:product:join:edit']",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        type: "primary",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("立即提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }