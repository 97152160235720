var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "div",
        { staticClass: "container_box" },
        [
          _c("pages-header", {
            ref: "pageHeader",
            attrs: {
              title: "添加直播产品",
              backUrl: "/marketing/broadcast/product",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card box-body mt14",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.form, "label-width": "120px", size: "small" },
            },
            [
              !_vm.isEdit
                ? _c("div", { staticClass: "acea-row row-between-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "acea-row mb20" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addGoods()
                              },
                            },
                          },
                          [_vm._v("添加产品")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", disabled: _vm.isShowCheck },
                            on: { click: _vm.batchDel },
                          },
                          [_vm._v("批量删除")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "tableList",
                  staticClass: "tableSelection",
                  staticStyle: { width: "100%" },
                  attrs: { "row-key": "id", data: _vm.proData, size: "small" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  !_vm.isEdit
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      "min-width": "150",
                      label: "产品名称",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                maxlength: "14",
                                placeholder: "最长14个汉字",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  return _vm.keyupEvent(
                                    scope.$index,
                                    scope.row.name
                                  )
                                },
                              },
                              model: {
                                value: scope.row.name,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "产品图", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "upLoadPicBox line-heightOne",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalPicTap(
                                      "1",
                                      "duo",
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                scope.row.image
                                  ? _c(
                                      "div",
                                      { staticClass: "pictrue tabPic" },
                                      [
                                        _c("img", {
                                          attrs: { src: scope.row.image },
                                        }),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "upLoad tabPic" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-camera cameraIconfont",
                                        }),
                                      ]
                                    ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "价格类型", "min-width": "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: scope.row.priceType,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "priceType", $$v)
                                  },
                                  expression: "scope.row.priceType",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "直播价", "min-width": "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              staticClass: "input_width",
                              attrs: {
                                type: "number",
                                precision: 2,
                                min: 0.01,
                                max: 99999,
                                controls: false,
                              },
                              model: {
                                value: scope.row.price,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "price", $$v)
                                },
                                expression: "scope.row.price",
                              },
                            }),
                            _vm._v(" "),
                            scope.row.priceType === 2 ||
                            scope.row.priceType === 3
                              ? _c("span")
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.priceType === 2 ||
                            scope.row.priceType === 3
                              ? _c("el-input-number", {
                                  staticClass: "input_width",
                                  attrs: {
                                    type: "number",
                                    precision: 2,
                                    min: 0.01,
                                    max: 99999,
                                    controls: false,
                                  },
                                  model: {
                                    value: scope.row.price2,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "price2", $$v)
                                    },
                                    expression: "scope.row.price2",
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  !_vm.isEdit
                    ? _c("el-table-column", {
                        attrs: { label: "操作", width: "70", fixed: "right" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.sku
                                    ? _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3776135835
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "fixed-card",
          attrs: { "dis-hover": "", shadow: "never", bordered: false },
        },
        [
          _c(
            "div",
            { staticClass: "acea-row row-center-wrapper" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:seckill:product:add"],
                      expression: "['merchant:seckill:product:add']",
                    },
                    {
                      name: "debounceClick",
                      rawName: "v-debounceClick",
                      value: function () {
                        _vm.submitForm()
                      },
                      expression:
                        "\n          () => {\n            submitForm();\n          }\n        ",
                    },
                  ],
                  attrs: { type: "primary", size: "small" },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }