var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formValidate",
          staticClass: "demo-formValidate",
          attrs: {
            model: _vm.formValidate,
            rules: _vm.rules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品：", prop: "productId" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:product:page:list"],
                      expression: "['merchant:product:page:list']",
                    },
                  ],
                  staticClass: "upLoadPicBox",
                  on: { click: _vm.changeGood },
                },
                [
                  _vm.formValidate.productId
                    ? _c("div", { staticClass: "pictrue" }, [
                        _c("img", { attrs: { src: _vm.image } }),
                      ])
                    : _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "labeltop",
              attrs: { label: "产品属性：", required: "" },
            },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.ManyAttrValue,
                    "tooltip-effect": "dark",
                    size: "mini",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "选择", width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: scope.row.id },
                                nativeOn: {
                                  change: function ($event) {
                                    return _vm.changeType(scope.row)
                                  },
                                },
                                model: {
                                  value: _vm.formValidate.attrValueId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formValidate,
                                      "attrValueId",
                                      $$v
                                    )
                                  },
                                  expression: "formValidate.attrValueId",
                                },
                              },
                              [_vm._v(" ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.manyTabDate
                    ? _vm._l(_vm.manyTabDate, function (item, iii) {
                        return _c("el-table-column", {
                          key: iii,
                          attrs: {
                            label: _vm.manyTabTit[iii].title,
                            "min-width": "80",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", {
                                      staticClass: "priceBox",
                                      domProps: {
                                        textContent: _vm._s(scope.row[iii]),
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "图片", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "upLoadPicBox" },
                              [
                                _c("el-image", {
                                  staticClass: "tabPic",
                                  attrs: {
                                    src: scope.row.image,
                                    "preview-src-list": [scope.row.image],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.attrValue, function (item, iii) {
                    return _c("el-table-column", {
                      key: iii,
                      attrs: {
                        label: _vm.formThead[iii].title,
                        "min-width": "140",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                _c("span", {
                                  staticClass: "priceBox",
                                  domProps: { textContent: _vm._s(row[iii]) },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "用户名称：", prop: "nickname" } },
            [
              _c("el-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.formValidate.nickname,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formValidate,
                      "nickname",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formValidate.nickname",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "评价文字：", prop: "comment" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.formValidate.comment,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formValidate,
                      "comment",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formValidate.comment",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "productScore",
              attrs: { label: "评价星级：", prop: "star" },
            },
            [
              _c("el-rate", {
                model: {
                  value: _vm.formValidate.star,
                  callback: function ($$v) {
                    _vm.$set(_vm.formValidate, "star", $$v)
                  },
                  expression: "formValidate.star",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "用户头像：", prop: "avatar" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "merchant:category:list:tree",
                        "merchant:product:page:list",
                      ],
                      expression:
                        "['merchant:category:list:tree', 'merchant:product:page:list']",
                    },
                  ],
                  staticClass: "upLoadPicBox",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("1")
                    },
                  },
                },
                [
                  _vm.formValidate.avatar
                    ? _c("div", { staticClass: "pictrue" }, [
                        _c("img", { attrs: { src: _vm.formValidate.avatar } }),
                      ])
                    : _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "评价图片：" } }, [
            _c(
              "div",
              { staticClass: "acea-row" },
              [
                _vm._l(_vm.formValidate.pics, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "pictrue",
                      attrs: { draggable: "false" },
                      on: {
                        dragstart: function ($event) {
                          return _vm.handleDragStart($event, item)
                        },
                        dragover: function ($event) {
                          $event.preventDefault()
                          return _vm.handleDragOver($event, item)
                        },
                        dragenter: function ($event) {
                          return _vm.handleDragEnter($event, item)
                        },
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("img", { attrs: { src: item } }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-error btndel",
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(index)
                          },
                        },
                      }),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.formValidate.pics < 10
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: [
                              "merchant:category:list:tree",
                              "merchant:product:page:list",
                            ],
                            expression:
                              "['merchant:category:list:tree', 'merchant:product:page:list']",
                          },
                        ],
                        staticClass: "upLoadPicBox",
                        on: {
                          click: function ($event) {
                            return _vm.modalPicTap("2")
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "upLoad" }, [
                          _c("i", {
                            staticClass: "el-icon-camera cameraIconfont",
                          }),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer-inner",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.resetForm("formValidate")
                },
              },
            },
            [_vm._v("重置")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:product:update"],
                  expression: "['merchant:product:update']",
                },
              ],
              attrs: { size: "mini", type: "primary", loading: _vm.loadingbtn },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formValidate")
                },
              },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }