var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, rules: _vm.rules, "label-width": "100px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { required: "", label: "管理员密码", prop: "password" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "管理员密码",
                  type: "password",
                  clearable: "",
                },
                model: {
                  value: _vm.pram.password,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.pram,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "pram.password",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.pram.password
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    required: "",
                    label: "确认密码",
                    prop: "confirmPassword",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: "确认密码",
                      clearable: "",
                    },
                    model: {
                      value: _vm.pram.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.pram,
                          "confirmPassword",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "pram.confirmPassword",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["merchant:admin:update:password"],
                      expression: "['merchant:admin:update:password']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("pram")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }