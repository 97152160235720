var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card",
          attrs: { shadow: "never", bordered: false },
        },
        [
          _c("div", {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          }),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.tableData },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "180" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "物流公司名称",
                  "min-width": "150",
                  prop: "name",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { "min-width": "200", label: "编码", prop: "code" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  label: "排序",
                  prop: "sort",
                  sortable: "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "是否显示", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["admin:express:update:show"])
                          ? _c("el-switch", {
                              staticClass: "demo",
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                                disabled: true,
                              },
                              model: {
                                value: scope.row.isShow,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isShow", $$v)
                                },
                                expression: "scope.row.isShow",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }