var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox", staticStyle: { "padding-bottom": "0" } },
    [
      _c(
        "el-row",
        { staticClass: "ranking", attrs: { gutter: 14 } },
        [
          _c(
            "el-col",
            _vm._b(
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: ["merchant:statistics:home:product:pay:ranking"],
                    expression:
                      "['merchant:statistics:home:product:pay:ranking']",
                  },
                ],
                staticClass: "ivu-mb mb14",
              },
              "el-col",
              _vm.grid,
              false
            ),
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  attrs: { shadow: "never", bordered: false },
                },
                [
                  _c("div", { staticClass: "header_title_line" }, [
                    _vm._v("产品支付排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "mt20",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.payData },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "排名", width: "60" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "index_common",
                                    class: [
                                      scope.$index + 1 == "1"
                                        ? "index_one"
                                        : scope.$index + 1 == "2"
                                        ? "index_two"
                                        : scope.$index + 1 == "3"
                                        ? "index_three"
                                        : "index_more",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.$index + 1) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产品信息", "min-width": "320" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "product_info" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "demo-image__preview line-heightOne",
                                    },
                                    [
                                      scope.row.image
                                        ? _c("el-image", {
                                            staticClass: "img",
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          })
                                        : _c("img", {
                                            staticClass: "img",
                                            staticStyle: {
                                              width: "36px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              src: _vm.defaultImg,
                                              alt: "",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "m-l-10 line1" }, [
                                    _vm._v(_vm._s(scope.row.proName)),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "salesAmount", label: "支付金额" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            _vm._b(
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: [
                      "merchant:statistics:home:product:pageview:ranking",
                    ],
                    expression:
                      "['merchant:statistics:home:product:pageview:ranking']",
                  },
                ],
                staticClass: "ivu-mb mb14",
              },
              "el-col",
              _vm.grid,
              false
            ),
            [
              _c(
                "el-card",
                {
                  staticClass: "box-card",
                  attrs: { shadow: "never", bordered: false },
                },
                [
                  _c("div", { staticClass: "header_title_line" }, [
                    _vm._v("产品访客排行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticClass: "mt20",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.pageviewData },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "排名", width: "60" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "index_common",
                                    class: [
                                      scope.$index + 1 == "1"
                                        ? "index_one"
                                        : scope.$index + 1 == "2"
                                        ? "index_two"
                                        : scope.$index + 1 == "3"
                                        ? "index_three"
                                        : "index_more",
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.$index + 1) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "产品信息", "min-width": "320" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "product_info" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "demo-image__preview line-heightOne",
                                    },
                                    [
                                      scope.row.image
                                        ? _c("el-image", {
                                            staticClass: "img",
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          })
                                        : _c("img", {
                                            staticClass: "img",
                                            staticStyle: {
                                              width: "36px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              src: _vm.defaultImg,
                                              alt: "",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "m-l-10 line1" }, [
                                    _vm._v(_vm._s(scope.row.proName)),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "pageView", label: "访问量" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }