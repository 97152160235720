var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.selectModel
        ? [
            _c("el-tree", {
              ref: "tree",
              attrs: {
                data: _vm.treeList,
                "show-checkbox": "",
                "node-key": "id",
                "default-checked-keys": _vm.selectModelKeysNew,
                props: _vm.treeProps,
              },
              on: { check: _vm.getCurrentNode },
            }),
          ]
        : [
            _c(
              "div",
              { staticClass: "divBox" },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    attrs: {
                      "body-style": { padding: "20px 20px 20px" },
                      shadow: "never",
                      bordered: false,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["merchant:product:category:add"],
                            expression: "['merchant:product:category:add']",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddMenu({
                              id: 0,
                              name: "顶层目录",
                            })
                          },
                        },
                      },
                      [_vm._v("新增" + _vm._s(_vm.biztype.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        staticClass: "table mt20",
                        attrs: {
                          data: _vm.dataList,
                          size: "small",
                          "highlight-current-row": "",
                          "row-key": "id",
                          "tree-props": {
                            children: "children",
                            hasChildren: "hasChildren",
                          },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "名称",
                            "min-width": "200",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.name) +
                                      " | " +
                                      _vm._s(scope.row.id) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        !_vm.selectModel
                          ? [
                              _c("el-table-column", {
                                attrs: { label: "分类图标", "min-width": "80" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "demo-image__preview line-heightOne",
                                            },
                                            [
                                              scope.row.icon
                                                ? _c("el-image", {
                                                    attrs: {
                                                      src: scope.row.icon,
                                                      "preview-src-list": [
                                                        scope.row.icon,
                                                      ],
                                                    },
                                                  })
                                                : _c("img", {
                                                    attrs: {
                                                      src: _vm.defaultImg,
                                                      alt: "",
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1102933446
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "排序",
                                  prop: "sort",
                                  "min-width": "150",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { label: "状态", "min-width": "150" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm.checkPermi([
                                            "merchant:category:update:status",
                                          ])
                                            ? _c("el-switch", {
                                                attrs: {
                                                  "active-value": true,
                                                  "inactive-value": false,
                                                  "active-text": "显示",
                                                  "inactive-text": "隐藏",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.onchangeIsShow(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.isShow,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "isShow",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.isShow",
                                                },
                                              })
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.isShow
                                                      ? "显示"
                                                      : "隐藏"
                                                  )
                                                ),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1067323246
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "180",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.pid === 0 &&
                                          _vm.checkPermi([
                                            "merchant:product:category:add",
                                          ])
                                            ? _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleAddMenu(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("添加子目录")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermi",
                                                  rawName: "v-hasPermi",
                                                  value: [
                                                    "merchant:product:category:update",
                                                  ],
                                                  expression:
                                                    "['merchant:product:category:update']",
                                                },
                                              ],
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditMenu(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _vm._v(" "),
                                          _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "hasPermi",
                                                  rawName: "v-hasPermi",
                                                  value: [
                                                    "merchant:product:category:delete",
                                                  ],
                                                  expression:
                                                    "['merchant:product:category:delete']",
                                                },
                                              ],
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelMenu(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2496963120
                                ),
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-bottom",
          attrs: {
            title:
              _vm.editDialogConfig.isCreate === 0
                ? "创建" + _vm.biztype.name
                : "编辑" + _vm.biztype.name,
            visible: _vm.editDialogConfig.visible,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            width: "540px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialogConfig, "visible", $event)
            },
          },
        },
        [
          _vm.editDialogConfig.visible
            ? _c("edit", {
                attrs: {
                  prent: _vm.editDialogConfig.prent,
                  "is-create": _vm.editDialogConfig.isCreate,
                  "edit-data": _vm.editDialogConfig.data,
                  biztype: _vm.editDialogConfig.biztype,
                  "all-tree-list": _vm.dataList,
                },
                on: { hideEditDialog: _vm.hideEditDialog },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }