var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog-bottom",
              attrs: {
                title: "产品信息",
                visible: _vm.dialogVisible,
                width: "540px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("div", { staticClass: "box-container" }, [
                    _vm.isEdit
                      ? _c(
                          "div",
                          { staticClass: "list" },
                          [
                            _c("label", { staticClass: "name" }, [
                              _vm._v("排序："),
                            ]),
                            _vm._v(" "),
                            _c("el-input", {
                              staticClass: "selWidth",
                              staticStyle: { "padding-right": "0" },
                              attrs: {
                                type: "number",
                                placeholder: "请输入序号",
                                size: "small",
                              },
                              model: {
                                value: _vm.FormData.sort,
                                callback: function ($$v) {
                                  _vm.$set(_vm.FormData, "sort", _vm._n($$v))
                                },
                                expression: "FormData.sort",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "list sp" }, [
                          _c("label", { staticClass: "name" }, [
                            _vm._v("排序："),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "info" }, [
                            _vm._v(_vm._s(_vm.FormData.sort)),
                          ]),
                        ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dialog-footer" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                _vm.dialogVisible = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleSort },
                          },
                          [_vm._v("确定")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }