var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _vm.checkPermi(["merchant:refund:order:page:list"])
        ? _c(
            "el-card",
            {
              staticClass: "ivu-mt",
              attrs: {
                bordered: false,
                shadow: "never",
                "body-style": { padding: 0 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "padding-add" },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-position": "right",
                      },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "退款单号：" } },
                        [
                          _c("el-input", {
                            staticClass: "form_content_width",
                            attrs: {
                              placeholder: "请输入退款单号",
                              size: "small",
                              clearable: "",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleSearchList($event)
                              },
                            },
                            model: {
                              value: _vm.tableFrom.refundOrderNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableFrom,
                                  "refundOrderNo",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "tableFrom.refundOrderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "订单编号：", "label-width": "66px" },
                        },
                        [
                          _c("el-input", {
                            staticClass: "form_content_width",
                            attrs: {
                              placeholder: "请输入订单号",
                              size: "small",
                              clearable: "",
                            },
                            model: {
                              value: _vm.tableFrom.orderNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tableFrom,
                                  "orderNo",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "tableFrom.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "时间选择：" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "form_content_width",
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              format: "yyyy-MM-dd",
                              size: "small",
                              type: "daterange",
                              placement: "bottom-end",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            on: { change: _vm.onchangeTime },
                            model: {
                              value: _vm.timeVal,
                              callback: function ($$v) {
                                _vm.timeVal = $$v
                              },
                              expression: "timeVal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleSearchList },
                            },
                            [_vm._v("查询")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            "body-style": { padding: "0 20px 20px" },
            shadow: "never",
            bordered: false,
          },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "list-tabs",
              on: { "tab-click": _vm.handleSearchList },
              model: {
                value: _vm.tableFrom.refundStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "refundStatus", $$v)
                },
                expression: "tableFrom.refundStatus",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  name: "9",
                  label: "全部(" + (_vm.orderChartType.all || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "0",
                  label: "待审核(" + (_vm.orderChartType.await || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "2",
                  label: "退款中(" + (_vm.orderChartType.refunding || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "4",
                  label:
                    "用户退货(" +
                    (_vm.orderChartType.awaitReturning || 0) +
                    ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "5",
                  label:
                    "商家待收货(" +
                    (_vm.orderChartType.awaitReceiving || 0) +
                    ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "6",
                  label: "已撤销(" + (_vm.orderChartType.revoke || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "1",
                  label: "审核未通过(" + (_vm.orderChartType.reject || 0) + ")",
                },
              }),
              _vm._v(" "),
              _c("el-tab-pane", {
                attrs: {
                  name: "3",
                  label: "已退款(" + (_vm.orderChartType.refunded || 0) + ")",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table mt5",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
                "row-key": function (row) {
                  return row.refundOrderNo
                },
              },
            },
            [
              _vm.checkedCities.includes("退款单号")
                ? _c("el-table-column", {
                    attrs: { label: "退款单号", "min-width": "185" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "acea-row" }, [
                                _c("span", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.type === 1,
                                      expression: "scope.row.type === 1",
                                    },
                                  ],
                                  staticClass: "iconfont icon-shipinhao mr5",
                                  staticStyle: { color: "#f6ae02" },
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticStyle: { display: "block" },
                                  domProps: {
                                    textContent: _vm._s(
                                      scope.row.refundOrderNo
                                    ),
                                  },
                                }),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3924775764
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("订单号")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "orderNo",
                      label: "订单号",
                      "min-width": "180",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("用户昵称")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "userNickName",
                      label: "用户昵称",
                      "min-width": "180",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("退款金额")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "refundPrice",
                      label: "退款金额",
                      "min-width": "100",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("退款状态")
                ? _c("el-table-column", {
                    attrs: { label: "退款状态", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "tag-padding",
                                  class: _vm.tagClass[scope.row.refundStatus],
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("refundStatusFilter")(
                                        scope.row.refundStatus
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      934997423
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("售后类型")
                ? _c("el-table-column", {
                    attrs: { label: "售后类型", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.afterSalesType === 1
                                      ? "仅退款"
                                      : "退货退款"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1074687961
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("退货类型")
                ? _c("el-table-column", {
                    attrs: { label: "退货类型", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.returnGoodsType === 0
                                      ? "不退货"
                                      : scope.row.returnGoodsType === 1
                                      ? "快递退回"
                                      : "到店退货"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2297101258
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("强制退款")
                ? _c("el-table-column", {
                    attrs: { label: "强制退款", "min-width": "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.isCompulsoryRefund ? "是" : "不是"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2629721509
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkedCities.includes("创建时间")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      "min-width": "150",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "190",
                  fixed: "right",
                  "render-header": _vm.renderHeader,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.refundStatus !== 0 &&
                        scope.row.refundStatus !== 5 &&
                        _vm.checkPermi(["merchant:refund:order:detail"])
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.onOrderDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.refundStatus === 0 &&
                        _vm.checkPermi(["merchant:refund:order:audit"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleApprovedReview(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("同意")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.refundStatus === 0 &&
                        _vm.checkPermi(["merchant:refund:order:audit"])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOrderRefuse(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("拒绝")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.refundStatus === 5 &&
                        _vm.checkPermi([
                          "merchant:refund:order:receiving:reject",
                        ])
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRefuseReceipt(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("拒绝")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.refundStatus === 5 &&
                        _vm.checkPermi(["merchant:refund:order:receiving"])
                          ? [
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "debounceClick",
                                      rawName: "v-debounceClick",
                                      value: function () {
                                        _vm.handleConfirmReceipt(
                                          scope.row.refundOrderNo
                                        )
                                      },
                                      expression:
                                        "\n                () => {\n                  handleConfirmReceipt(scope.row.refundOrderNo);\n                }\n              ",
                                    },
                                  ],
                                },
                                [_vm._v("确认收货")]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        scope.row.refundStatus === 0 ||
                        scope.row.refundStatus === 5
                          ? _c(
                              "el-dropdown",
                              { attrs: { trigger: "click" } },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm._v(" 更多"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _vm.checkPermi([
                                      "merchant:refund:order:detail",
                                    ])
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.onOrderDetails(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("详情\n              ")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.checkPermi([
                                      "merchant:refund:order:mark",
                                    ])
                                      ? _c(
                                          "el-dropdown-item",
                                          {
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.onOrderMark(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("备注\n              ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["merchant:refund:order:mark"]) &&
                        scope.row.refundStatus !== 0 &&
                        scope.row.refundStatus !== 5
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.onOrderMark(scope.row)
                                  },
                                },
                              },
                              [_vm._v("备注")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-sizes": _vm.$constants.page.limit,
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-card", {
        staticClass: "box-card",
        attrs: { shadow: "never", bordered: false },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.card_select_show,
              expression: "card_select_show",
            },
          ],
          staticClass: "card_abs",
        },
        [
          [
            _c(
              "div",
              { staticClass: "cell_ht" },
              [
                _c(
                  "el-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v("全选\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.checkSave()
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                on: { change: _vm.handleCheckedCitiesChange },
                model: {
                  value: _vm.checkedCities,
                  callback: function ($$v) {
                    _vm.checkedCities = $$v
                  },
                  expression: "checkedCities",
                },
              },
              _vm._l(_vm.columnData, function (item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item,
                    staticClass: "check_cell",
                    attrs: { label: item },
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              1
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.drawerVisible
        ? _c("refund-order-detail", {
            ref: "orderDetail",
            attrs: {
              drawerVisible: _vm.drawerVisible,
              refundOrderNo: _vm.refundOrderNo,
            },
            on: {
              onClosedrawerVisible: _vm.onClosedrawerVisible,
              getReviewSuccessful: _vm.getReviewSuccessful,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-bottom",
          attrs: {
            title: "同意退款",
            visible: _vm.dialogVisibleAgreeToReturn,
            width: "900px",
            "before-close": _vm.handleCloseAgreeToReturn,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleAgreeToReturn = $event
            },
          },
        },
        [
          _vm.dialogVisibleAgreeToReturn
            ? _c("agree-to-return", {
                ref: "agreeToReturn",
                attrs: { refundInfo: _vm.refundInfo },
                on: {
                  onHandleCancel: _vm.handleCloseAgreeToReturn,
                  onHandleSuccess: _vm.handleSuccess,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }