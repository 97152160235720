var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card mt14",
          attrs: {
            shadow: "never",
            bordered: false,
            "body-style": { padding: "20px" },
          },
        },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: ["merchant:address:add"],
                  expression: "['merchant:address:add']",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleAddAddress },
            },
            [_vm._v("添加地址")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "mt20",
              attrs: { data: _vm.tableData.data, size: "small" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": true,
                  label: "商家地址",
                  "min-width": "280",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: scope.row.isDefault,
                                expression: "scope.row.isDefault",
                              },
                            ],
                            staticClass: "mr5",
                            staticStyle: { color: "#409eff" },
                          },
                          [_vm._v("[默认退货]")]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(scope.row.detail))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receiverName",
                  label: "商家姓名",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receiverPhone",
                  label: "商家电话",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "是否开启",
                  "min-width": "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["merchant:address:update:show"])
                          ? _c("el-switch", {
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                                "active-text": "开启",
                                "inactive-text": "关闭",
                                disabled: scope.row.isDefault,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onchangeIsShow(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.isShow,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isShow", $$v)
                                },
                                expression: "scope.row.isShow",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(scope.row.isShow ? "开启" : "关闭")
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:address:update"],
                                expression: "['merchant:address:update']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleEditAddress(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        !scope.row.isDefault &&
                        _vm.checkPermi(["merchant:address:set:default"]) &&
                        scope.row.isShow
                          ? [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSetIsDefault(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("设为默认")]
                              ),
                              _vm._v(" "),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "hasPermi",
                                rawName: "v-hasPermi",
                                value: ["merchant:address:delete"],
                                expression: "['merchant:address:delete']",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.handleDelAddress(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formData.id === 0 ? "新增地址" : "编辑地址",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "540px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c(
                "el-form",
                {
                  ref: "formData",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商家地址：", prop: "detail" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请填写商家地址",
                        },
                        model: {
                          value: _vm.formData.detail,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "detail",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.detail",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商家姓名：", prop: "receiverName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请填写商家姓名" },
                        model: {
                          value: _vm.formData.receiverName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "receiverName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.receiverName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商家电话：", prop: "receiverPhone" } },
                    [
                      _c("el-input", {
                        staticClass: "width100",
                        attrs: {
                          maxlength: "11",
                          placeholder: "请填写商家电话",
                        },
                        model: {
                          value: _vm.formData.receiverPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "receiverPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.receiverPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开启状态：" } },
                    [
                      _c("el-switch", {
                        staticClass: "mr20",
                        attrs: {
                          "active-text": "开启",
                          "inactive-text": "关闭",
                        },
                        model: {
                          value: _vm.formData.isShow,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isShow", $$v)
                          },
                          expression: "formData.isShow",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.formData.isShow,
                              expression: "formData.isShow",
                            },
                          ],
                          model: {
                            value: _vm.formData.isDefault,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isDefault", $$v)
                            },
                            expression: "formData.isDefault",
                          },
                        },
                        [_vm._v("设为默认发货地址")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.btnLoading },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }